<template>
	<br-app-booter text-color="white" progressbar-color="#037cff" logo="/_businessFiles/logo-white.svg" :logo-width="400" logo-style="margin-top:15%;" background-color="#1e1e1e" :background-image="backgroundImage">
		<!--
			NOTE: We can override the default slot like this: Check BrAppBooter.vue for more info
				<template #default="{isSpinning, errorMsg, reboot}">...</template>
		-->
		
		<template #dialogs>
			<christmas-w-contest-and-free-virtual-popup v-if="$bREST.christmasWContestAndFreeVirtual_isInRange" />
		</template>
	</br-app-booter>
</template>

<script>
	
	export default {
		components: {
			ChristmasWContestAndFreeVirtualPopup: () => import("@/custom/routerViews/accountCreation_christmasWContestAndFreeVirtual/Popup.vue"),
		},
		computed: {
			backgroundImage() { return `/_businessFiles/bootBgs/${this.$bREST.uiBreakpoint.name==="xs"?"mobile":"desktop"}.jpg`; },
		},
	};
	
</script>

<style> @import './App.css'; </style>
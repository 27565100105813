<!-- Default component used when building lists, unless we defined a <template #data-table-area> in our list implementation - but we could still use it inside manually -->
<template>
	<v-data-table :headers="listComponent.currentBreakpoint_vDataTableHeaderDefs"
					item-key="frontendUUID"
					:items="listComponent.final_items"
					:items-per-page="listComponent.final_paging_size"
					:page="listComponent.final_paging_index"
					:server-items-length="listComponent.final_server_nbRecords"
					@[listComponent.useForLoading?`update:options`:null]="listComponent.on_dataTablePaginationOrSort_change($event)"
					:footer-props="listComponent.final_footerProps"
					:search="listComponent.ctrlF3_val"
					v-model="listComponent.selectedItems"
					@input="listComponent.on_selectedItems_change()"
					selectable-key="isSelectable"
					:show-select="listComponent.final_checkboxes_show"
					:single-select="listComponent.final_isPicker_isSingle"
					class="elevation-1"
					:custom-filter="customFilterCallback"
					:[!listComponent.useForLoading?`custom-sort`:null]="customSortCallback"
					multi-sort
					:loading="listComponent.final_isLoading"
					:mobile-breakpoint="GENERIC_LIST_CONSTS.NEVER_SWITCH_TO_MOBILE_REPRESENTATION_BREAKPOINT"
					dense
	> <!-- Still need events like @update:items-per-page to know when paging changes -->
		
		<!-- Body rows -->
		<template #item="{ index, item:{rowInfo:loop_rowInfo}, isMobile, isSelected, select }">
			<tr :class="listComponent.row_evalClass(loop_rowInfo)"
			    :style="listComponent.row_evalStyle(loop_rowInfo)"
				@click.stop.prevent="loop_rowInfo.isClickable ? listComponent.on_row_click(loop_rowInfo,$event.ctrlKey) : null"
				@mousedown.middle="  loop_rowInfo.isClickable ? listComponent.on_row_click(loop_rowInfo,true)           : null"
			>
				
				<!-- Checkbox cell -->
				<td class="text-start" v-if="listComponent.final_checkboxes_show">
					<template v-if="loop_rowInfo.isSelectable">
						<template v-if="listComponent.final_isPicker_isSingle">
							<v-icon color="grey" class="d-inline-block" @click.stop.prevent="select(true)">mdi-circle-outline</v-icon>
							<v-fab-transition> <v-icon v-if="isSelected" color="green" class="d-inline-block ml-n6" @click.stop.prevent="select(false)">mdi-checkbox-marked-circle</v-icon> </v-fab-transition>
						</template>
						<template v-else>
							<v-simple-checkbox :value="isSelected" @input="select(!isSelected)" :ripple="false" />
						</template>
					</template>
				</td>
				
				<!-- Normal cells -->
				<td v-for="(loop_col,loop_idx) in listComponent.currentBreakpoint_cols"
					:key="loop_idx"
					:class="loop_col.style_tdClass(loop_rowInfo.model)"
					:style="loop_col.style_tdStyle(loop_rowInfo.model)"
					@click.stop.prevent="loop_rowInfo.cols[loop_col.name].isClickable ? listComponent.on_cell_click(loop_col,loop_rowInfo,$event.ctrlKey) : null"
					@mousedown.middle="  loop_rowInfo.cols[loop_col.name].isClickable ? listComponent.on_cell_click(loop_col,loop_rowInfo,true)           : null"
				>
					
					<!--
						If the usage passes a slot like <template #item.colName="{ rowInfo, colInfo, modelField:null, finalContent:null }">
						Note that it should start with a <div>.
						Check toVDataTableItem() docs
						Row info:
							{
								model:        B_REST_Model,
								isClickable:  <bool>
								isSelectable: <bool>
								actions:      [{action<B_REST_Vuetify_GenericList_RowAction>, isClickable:<bool>}],
								cols:         Map of col name => {col<B_REST_Vuetify_GenericList_Col>, modelField<B_REST_ModelField_x>:null, isEditable, isClickable}
							}
						Where:
							colInfo:      rowInfo.cols[<thisCol>]
							modelField:   colInfo.modelField | NULL
							finalContent: Result of col_valToText(), that calls B_REST_Vuetify_GenericList_Col::toCellContent() | modelField.val or modelField.toLabel() | NULL
						WARNING:
							"loop_rowInfo.cols[loop_col.name].modelField" is NULL if !B_REST_Vuetify_GenericList_Col::fieldDescriptor_isDB() (ex we have no field, multiple fields, or point on a lookup)
					-->
					<template v-if="col_definesTDSlot(loop_col)">
						<slot :name="loop_col.vDataTableSlotName" v-bind="{ rowInfo:loop_rowInfo, colInfo:loop_rowInfo.cols[loop_col.name], modelField:col_getModelField(loop_rowInfo,loop_col,false), finalContent:col_valToText(loop_rowInfo,loop_col) }" />
					</template>
					
					<!-- If we get here, it's sure we have a SINGLE fieldNamePath & fieldDescriptor behind the col + that it's editable -->
					<br-field-db v-else-if="loop_rowInfo.cols[loop_col.name].isEditable" :field="col_getModelField(loop_rowInfo,loop_col,true)" no-label v-bind="loop_col.vBind" v-on="loop_col.vOn" />
					
					<!-- Either we have a non-editable SINGLE fieldNamePath & fieldDescriptor, or multiple field name paths but we defined col's toCellContent()  -->
					<div v-else :key="loop_idx" v-text="col_valToText(loop_rowInfo,loop_col)" />
					
				</td>
				
				<!-- Actions cell -->
				<td v-if="listComponent.rowActions_uses" class="text-center">
					<v-tooltip v-for="(loop_actionInfo,loop_actionInfo_idx) in loop_rowInfo.actions" :key="`action-${loop_actionInfo_idx}`" bottom :disabled="loop_actionInfo.tooltip===null">
						{{ loop_actionInfo.tooltip }}
						<template #activator="{ on, attrs }">
							<span v-bind="attrs" v-on="on"> <!-- NOTE: Span needed for when btn is disabled, otherwise tooltip won't appear -->
								<v-btn :disabled="loop_actionInfo.isClickable!==true"
									class="mx-1"
									v-bind="loop_actionInfo.action.getBtnAttrs(loop_actionInfo.isClickable)"
									@click.stop.prevent="loop_actionInfo.isClickable===true ? listComponent.on_rowAction_click(loop_actionInfo.action,loop_rowInfo,$event.ctrlKey) : null"
									@mousedown.middle="  loop_actionInfo.isClickable===true ? listComponent.on_rowAction_click(loop_actionInfo.action,loop_rowInfo,true)           : null"
								>
									<v-icon small v-text="loop_actionInfo.action.icon" />
								</v-btn>
							</span>
						</template>
					</v-tooltip>
				</td>
				
			</tr>
		</template>
		
	</v-data-table>
</template>

<script>
	
	import { GENERIC_LIST_CONSTS } from "./BrGenericListBase.vue";

	
	
	export default {
		props: {
			listComponent: {type:Object, required:true}, //Derived component instance using this base component
		},
		data()
		{
			return {
				GENERIC_LIST_CONSTS,
			};
		},
		methods: {
			col_definesTDSlot(col) { return !!this.$scopedSlots[col.vDataTableSlotName]; },
			customFilterCallback(loop_cellVal, nonEmptySearchString, loop_item)
			{
				return this.listComponent.on_ctrlF3_update(loop_cellVal, nonEmptySearchString, loop_item);
			},
			customSortCallback(filteredItems, sortedColList_names, sortedColList_isDesc, locale, customSorters)
			{
				return this.listComponent.on_sort_update(filteredItems, sortedColList_names, sortedColList_isDesc, locale, customSorters);
			},
			col_getModelField(rowInfo, col, intendedForBrFieldDb)
			{
				const colName = col.name;
				const modelField = rowInfo.cols[colName].modelField;
				if (!modelField && intendedForBrFieldDb) { this.listComponent.throwEx(`Col ${colName} req to have a modelField (depending on a single field) if we want to use it in an editable <br-field-db>`); }
				
				return modelField;
			},
			/*
			NOTE: modelField could be NULL, ex if we forgot required fields (modelList.requiredFields.requiredFields_addFields()), but we could use col's toCellContent() if defined
			For toCellContent(), check B_REST_Vuetify_GenericList_Col docs. Allows to do something like:
				...
				toCellContent(col,model,defaultContent)
				{
					return defaultContent?.substr(0,10) ?? null; //Ex if was a YmdHis and just wanted Ymd
					return model.select_nonEmptyValsConcatenated("coordinates(street_number|street_name|appt_number)");
					return model.select_firstNonEmptyVal("assignedTo.firstName+assignedTo.lastName|assignedTo.userName");
				},
				...
			*/
			col_valToText(rowInfo, col)
			{
				//NOTE: Here, rowInfo.cols is a map of objs for -that- row, not of B_REST_Vuetify_GenericList_Col instances, though it contains a {col} prop pointing back on it
				
				const modelField = rowInfo.cols[col.name].modelField;
				let   text       = null;
				
				if (col.useToLabel)
				{
					const toLabel_targetModel = col.useToLabel_main ? rowInfo.model : (modelField.model_has?modelField.model:null);
					
					text = toLabel_targetModel?.toLabel(this.listComponent.quotedName) ?? null;
				}
				else if (modelField && !modelField.fieldDescriptor.type_is_json) { text=modelField.valToText(); }
				
				return col.toCellContent ? col.toCellContent.call(this.listComponent,col,rowInfo.model,text) : text;
			},
		},
	};
	
</script>

<style scoped>
	
	.br-generic-list--toRemoveOrDelete {
		background-color: #9e9e9e;
		pointer-events: none;
	}
	
</style>
<!--
	Usage ex:
		In a BrGenericFormBase der:
			<br-field-file :model="model" field="cv" @change="model_isNew?undefined:awaitUnsavedChangesSaved_filesOnly()" />
		Elsewhere:
			<br-field-file :model="model" field="cv" @change="model.isNew?undefined:model.awaitUnsavedChangesSaved_filesOnly()" />
		Standalone ex:
			<br-field-file :control="someControl" />
	We also have @pendingUpload:start, @pendingUpload:done & @delete events, but @change includes all of them
-->
<template>
	<v-container v-if="final_control" ref="container" class="br-field-file" :class="container_classes">
		
		<v-row dense>
			<v-col v-if="final_label" cols="12" class="base-file-input__label text-uppercase">
				<v-label> <v-icon v-if="brFieldFileAttrs.icon" v-text="brFieldFileAttrs.icon" /> {{ final_label }}</v-label>
			</v-col>
			
			<v-col cols="12">		
				<v-card :loading="hasOngoingAsyncTasks" @dragover.prevent="drag_on_enter" @dragenter.prevent="drag_on_enter" @dragleave.stop.prevent="drag_on_leave" @drop.prevent="drag_on_drop" :color="drag_is?'grey lighten-2':null" elevation="4" :style="card_style">
					<v-btn v-if="zip_can" absolute icon top right text @click.stop.prevent="items_download_allZipped"><v-icon>mdi-download-outline</v-icon></v-btn>
					
					<v-card-text v-if="!final_readonly && !(final_displayMode_isAvatar && items_has)">
						<v-sheet width="100%" class="d-flex flex-column align-center justify-center" color="transparent" @click.stop.prevent="openFilePicker" :style="items_canPutMore?'cursor:pointer':null">
							<v-icon :color="cloud_color" size="60">mdi-cloud-upload</v-icon>
							<p v-if="placeholder_show" class="text-center">{{ final_placeholder }}</p> <!-- In DISPLAY_MODE_AVATAR we don't have enough space to write labels -->
						</v-sheet>
					</v-card-text>
					
					<v-card-text v-if="items_has" :style="cardText_style">
						
						<!-- DISPLAY_MODE_LIST -->
						<template v-if="final_displayMode_isList">
							<v-list subheader two-line class="pb-4">
								<template v-for="(loop_item,loop_item_idx) in items">
									<v-divider :key="`divider-${loop_item.frontendUUID}`" v-if="!final_readonly || loop_item_idx>0" class="my-2" />
									<br-field-file-item-progressbar :key="`progress-${loop_item.frontendUUID}`" :item="loop_item" />
									<v-list-item :key="`item-${loop_item.frontendUUID}`" :ripple="false">
										<v-list-item-avatar tile :size="filePreviewSquareSize">
											<br-field-file-item-preview :item="loop_item" :width="filePreviewWidth" :height="filePreviewHeight" :square-size="filePreviewSquareSize" />
										</v-list-item-avatar>
										<v-list-item-content>
											<v-list-item-title    v-text="items_get_baseNameWExt(loop_item)" />
											<v-list-item-subtitle v-text="items_get_size_humanReadable(loop_item)" />
										</v-list-item-content>
										<v-list-item-action>
											<br-field-file-item-toolbar :item="loop_item" :small-icons="false" :prefix-filter="prefixFilter" @click:delete="items_toggle_remove_prompt(loop_item)" :readonly="final_readonly" />
										</v-list-item-action>
									</v-list-item>
								</template>
							</v-list>
						</template>
						
						<!-- DISPLAY_MODE_TILES -->
						<template v-if="final_displayMode_isTiles">
							<v-row justify="start" align="stretch" class="mb-2">
								<v-col v-for="(loop_item,loop_item_idx) in items" :key="`item-${loop_item.frontendUUID}`" cols="auto">
									<v-sheet height="100%" dark rounded class="pt-2" style="position:relative;" :style="{width:`${tiles_sheet_width}px`}">
										<div class="br-field-file--tiles--toolbar-wrapper">
											<br-field-file-item-toolbar :item="loop_item" :small-icons="tiles_toolbar_smallIcons" :prefix-filter="prefixFilter" @click:delete="items_toggle_remove_prompt(loop_item)" dark class="mt-3 mx-1" :readonly="final_readonly" />
										</div>
										<br-field-file-item-progressbar :item="loop_item" absolute />
										<br-field-file-item-preview :item="loop_item" :width="filePreviewWidth" :height="filePreviewHeight" :square-size="filePreviewSquareSize" class="mx-auto mt-4" />
										<div class="white--text text-center px-3 pb-3">
											<div class="text-body-2 my-2" v-text="items_get_baseNameWExt(loop_item)" />
											<div class="text-caption"     v-text="items_get_size_humanReadable(loop_item)" />
										</div>
									</v-sheet>
								</v-col>
							</v-row>
						</template>
						
						<!-- DISPLAY_MODE_AVATAR -->
						<template v-if="final_displayMode_isAvatar && items_first && !items_first.ifStored_toDelete">
							<v-sheet :key="`item-${items_first.frontendUUID}`" height="100%" dark rounded class="pt-2" style="position:relative;" @click.stop.prevent="openFilePicker">
								<!-- IMPORTANT: Keep the :key on the <v-sheet>, for when we change items, otherwise previews won't change -->
								<div class="br-field-file--tiles--toolbar-wrapper">
									<br-field-file-item-toolbar :item="items_first" :small-icons="avatar_toolbar_smallIcons" :prefix-filter="prefixFilter" @click:delete="items_toggle_remove_prompt(items_first)" dark class="mt-3 mx-1" :readonly="final_readonly" />
								</div>
								<br-field-file-item-progressbar :item="items_first" absolute />
								<br-field-file-item-preview :item="items_first" :width="filePreviewWidth" :height="filePreviewHeight" :square-size="filePreviewSquareSize" />
							</v-sheet>
						</template>
						
					</v-card-text>
					
					<v-card-text v-else-if="!final_displayMode_isAvatar && placeholder_show" class="text-center">
						<v-label>{{ translation_noFiles }}</v-label>
					</v-card-text>
				</v-card>
			</v-col>
			
			<v-col cols="12">
				<v-expand-transition>
					<v-messages v-if="userTouch_has && lastValidationResultErrorMsgs_has" class="error--text" :value="lastValidationResultErrorMsgs_translated" /> <!-- As per /node_modules/vuetify/src/components/VMessages/VMessages.ts, must be an arr -->
				</v-expand-transition>
			</v-col>
			
		</v-row>
		
		<input type="file" class="d-none" ref="hiddenFilePicker" :multiple="isMultiple" :accept="acceptMimePattern" />
		
		<br-prompt v-if="confirmDelPrompt" :instance="confirmDelPrompt" />
		
	</v-container>
</template>

<script>
	
	import { B_REST_Utils, B_REST_Model, B_REST_ModelFields, B_REST_FileControl } from "../../../../classes";
	import { B_REST_Vuetify_Prompt, B_REST_Vuetify_Prompt_Action } from "../prompt/B_REST_Vuetify_Prompt.js";
	import B_REST_VueApp_CreateCoreMixin from "../../B_REST_VueApp_CreateCoreMixin.js";
	
	
	
	const COMPONENT_NAME         = "BrFieldFile";
	const CORE_ALT_BASE_LOC_PATH = `app.components.${COMPONENT_NAME}`;
	
	const DISPLAY_MODE_TILES        = "tiles";   //Rectangle padded container with each tiles being tiles inside
	const DISPLAY_MODE_LIST         = "list";    //Vertical listing of files
	const DISPLAY_MODE_AVATAR       = "avatar";  //Single container with no padding, intended to display an img file in full width
	const DISPLAY_MODES = [DISPLAY_MODE_TILES, DISPLAY_MODE_LIST, DISPLAY_MODE_AVATAR];
	
	const DEFAULT_FILE_PREVIEW_SIZE = 200;
	
	const DRAG_STATE_OFF     = "off";
	const DRAG_STATE_ENTER   = "enter";
	const DRAG_STATE_LEAVING = "leaving";
	
	const CONFIRM_DEL_PROMPT_ACTIONS_CANCEL  = null;
	const CONFIRM_DEL_PROMPT_ACTIONS_PROCEED = "proceed";
	
	const ITEM_TOOLBAR_MIN_WIDTH = 120;
	
	const TILES_MIN_WIDTH = 150;
	
	const PLACEHOLDER_MIN_AREA = 170 * 150; //Of course, not really reliable if we have 150 * 170 instead...
	
	const VUETIFY_BREAKPOINTS                  = ["xs","sm","md","lg","xl"];
	const DEFAULT_SHORT_LABEL_UNTIL_BREAKPOINT = "sm";
	
	const SIDE_EFFECT_PREVENT_MEMORY_LEAKS = true;
	
	
	
	export default {
		name: COMPONENT_NAME,
		//This creates funcs like t(), and requires that component defines its name Vue prop. WARNING: Must define component's name too
		mixins: [
			B_REST_VueApp_CreateCoreMixin({
				coreAltBaseLocPath: CORE_ALT_BASE_LOC_PATH,
			}),
		],
		components: {
			BrPrompt:                   () => import("../prompt/BrPrompt.vue"),
			BrFieldFileItemToolbar:     () => import("./BrFieldFileItemToolbar.vue"),
			BrFieldFileItemProgressbar: () => import("./BrFieldFileItemProgressbar.vue"),
			BrFieldFileItemPreview:     () => import("./BrFieldFileItemPreview.vue"),
		},
		props: {
			/*
			Required props; either:
				-A field as B_REST_ModelField_File alone
				-A combination of model.select(<fieldNamePath>), where field is used as the fieldNamePath (and must end on a B_REST_ModelField_File)
				-A B_REST_FileControl instance (usually for custom files not related to models)
			Check _checkInit() docs for more info
			*/
			field:                     {type:undefined,          required:false, default:null, validator(val){ return val===null || val instanceof B_REST_ModelFields.File || B_REST_Utils.string_is(val); }}, //Either NULL, an instance of B_REST_ModelField_File, or a fieldNamePath for model.select(<fieldNamePath>)
			model:                     {type:B_REST_Model,       required:false, default:null},                      //Either NULL or a B_REST_Model instance, to get the final field via model.select(<fieldNamePath>)
			control:                   {type:B_REST_FileControl, required:false, default:null},                      //Either NULL or a B_REST_FileControl instance
			//Other stuff
			readonly:                  {type:Boolean,            required:false, default:null},
			displayMode:               {type:String,             required:false, default:null},                      //One of DISPLAY_MODE_x
			label:                     {type:String,             required:false, default:null},                      //Translated label (place localization where it's being used)
			shortLabel:                {type:String,             required:false, default:null},                      //Like for label, we also have a B_REST_ModelField_DB::shortLabel prop we can override
			shortLabelUntilBreakpoint: {type:String,             required:false, default:DEFAULT_SHORT_LABEL_UNTIL_BREAKPOINT, validator:validator_shortLabelUntilBreakpoint}, //Decides until which Vuetify breakpoint ($bREST.uiBreakpoint.name) to use shortLabel instead of label (whether the B_REST_ModelField_File one or overriden one)
			noLabel:                   {type:Boolean,            required:false, default:false},
			labelAbove:                {type:Boolean,            required:false, default:true},                      //NOTE: For now, all we can do is to put it on top, but for single ones could eventually do otherwise
			placeholder:               {type:String,             required:false, default:null},
			icon:                      {type:String,             required:false, default:null},                      //Ex "mdi-image"
			filePreviewWidth:          {type:Number,             required:false, default:DEFAULT_FILE_PREVIEW_SIZE}, //In tiles / list modes: for each item. In avatar mode: for the whole container
			filePreviewHeight:         {type:Number,             required:false, default:DEFAULT_FILE_PREVIEW_SIZE}, //Same as the above
			prefixFilter:              {type:String,             required:false, default:null},                      //For when we want a unique file list model field to be used for multiple dynamic purposes but that we don't want to create tons of _def_fileList() in model, and auto name and filter them like somePurpose-abc.jpg, somePurpose-def.jpg & someOtherPurpose-ghi.jpg, someOtherPurpose-jkl.jpg, so both components either see abc.jpg & def.jpg, or ghi.jpg & klm.jpg. So here, would pass either "somePurpose-" or "someOtherPurpose-"
			//Anything else that we try to pass, will fall inside v-bind="brFieldFileAttrs" (using $attrs)
		},
		watch: {
			field()   { this._checkInit(); },
			model()   { this._checkInit(); },
			control() { this._checkInit(); },
			hack_items_allStored_wCount()
			{
				if (this.hack_items_allStored_wCount) { this.userTouch_has=false; this._reSync_lastValidationResultErrorMsgs(); }
			},
		},
		data()
		{
			return {
				final_field:      null, //An opt B_REST_ModelField_File instance, either field, model.select(field), control.ifModelFiles_modelField or null
				final_control:    null, //Actual B_REST_FileControl instance to use
				confirmDelPrompt: null, //Optional instance of B_REST_Vuetify_Prompt
				drag: {
					state:                        DRAG_STATE_OFF, //One of DRAG_STATE_x -> Check drag_flickerHelper_start() docs
					flickerHelper_interval_ptr:   null,           //setInterval ptr
					flickerHelper_interval_delay: 100,
				},
				//Copies of what is found in B_REST_FileControl; we have to duplicate these to make more sense when we work w prefixFilter
					userTouch_has:                            false,
					lastValidationResultErrorMsgs_translated: [],
			};
		},
		created() { this._checkInit(); },
		mounted() { this._checkInit_setupEventListeners(); }, //NOTE: Don't switch to created(), because we need to use $refs
		beforeDestroy()
		{
			if (this.final_control)
			{
				this.drag_flickerHelper_stop();
				
				/*
				To avoid memory leaks, destroy items that were being in pending upload status. Check B_REST_FileControlItem::ifNewPreparing_releaseMemory() docs
				NOTE: Will seem mysterious why those will disappear from the control, but since the UI will be gone, we won't realize
				*/
				if (SIDE_EFFECT_PREVENT_MEMORY_LEAKS) { this.final_control.items_destroy_isNewPreparing(); }
			}
		},
		computed: {
			uid()                    { return this._uid;                                                       }, //All Vue component instances have a unique id
			brFieldFileAttrs()       { return {...this.$bREST.brFieldDbAttrs, ...this.$props, ...this.$attrs}; }, //Check B_REST_VueApp_base::brFieldFileAttrs docs + we allow overriding these default attrs, so don't switch both "...{}, ...{}" order. NOTE: $attrs only holds what we passed, so wouldn't include props w default vals, so we need to include $props
			isStandalone()           { return !this.final_field;                                               },
			final_field_descriptor() { return this.final_field?.fieldDescriptor;                               },
			final_field_name()       { return this.final_field_descriptor?.name || "[noname]";                 },
			final_field_quotedName() { return `<br-field-file field="${this.final_field_name}">`;              }, //Just for _throwField() and B_REST_Model::toLabel()
			final_label()
			{
				const debugFieldNamePath = this.$bREST.debug_fieldNamePaths ? `[${this.final_debugFieldNamePath}]` : null;
				
				if (this.brFieldFileAttrs.noLabel) { return debugFieldNamePath; }
				
				const labelFieldName = this.$bREST.uiBreakpoint_isXAndDown(this.brFieldFileAttrs.shortLabelUntilBreakpoint) ? "shortLabel" : "label";
				
				if (this[labelFieldName]) { return debugFieldNamePath ? `${this[labelFieldName]} ${debugFieldNamePath}` : this[labelFieldName]; }
				if (!this.final_field)    { return debugFieldNamePath; }
				
				let label = this.final_field[labelFieldName];
				if (label===null) { return debugFieldNamePath; }
				
				if (this.final_readonly) { label  = `🔓 ${label}`;            }
				if (debugFieldNamePath)  { label += ` ${debugFieldNamePath}`; }
				if (this.required)       { label += " *";                     }
				
				return label;
			},
				//Tries to ret a fieldNamePath for the field
				final_debugFieldNamePath()
				{
					if (this.field===null) { return "<unnamed>"; }
					if (this.field instanceof B_REST_ModelFields.File) { return this.field.debugFieldNamePath(); }
					return this.field; //If we get here, is a fieldNamePath and we don't have the model yet
				},
			//Get the smallest of width & height
			filePreviewSquareSize()
			{
				const width  = this.brFieldFileAttrs.filePreviewWidth;
				const height = this.brFieldFileAttrs.filePreviewHeight;
				
				return width<=height ? width : height;
			},
			final_displayMode()
			{
				if (this.brFieldFileAttrs.displayMode) { return this.brFieldFileAttrs.displayMode; }
				
				return this.isMultiple ? DISPLAY_MODE_TILES : DISPLAY_MODE_AVATAR;
			},
			final_displayMode_isTiles()  { return this.final_displayMode===DISPLAY_MODE_TILES;  },
			final_displayMode_isList()   { return this.final_displayMode===DISPLAY_MODE_LIST;   },
			final_displayMode_isAvatar() { return this.final_displayMode===DISPLAY_MODE_AVATAR; },	
			maxFileCount_progress()
			{
				this._assertNoPrefixFilter();
				return (this.items_has?`${this.items_count}/`:"") + `${this.maxFileCount} ${this.t_alt("files")}`; //Ex "1/3 files"
			},
			maxSize_progress()
			{
				this._assertNoPrefixFilter();
				return (this.items_has?`${this.items_size_humanReadable}/`:"") + this.maxSize_humanReadable; //Ex "10.00 mb/20.00 mb"
			},
			//Optionaly with something like " (max 2/3, 8mb/10mb)" to indicate we can add up to X file + within X byte size
			final_placeholder()
			{
				if (this.placeholder!==null) { return this.placeholder; }
				
				const tag_can   = this.items_canPutMore ? "can"      : "cant";
				const tag_mode  = this.isMultiple       ? "multiple" : "single";
				let placeholder = this.t_alt(`placeholder.${tag_can}.${tag_mode}`);
				
				const limitations = [];
					if (this.maxFileCount && this.isMultiple) { limitations.push(this.maxFileCount_progress); }
					if (this.maxSize)                         { limitations.push(this.maxSize_progress);      }
				if (limitations.length>0) { placeholder += ` (max ${limitations.join(', ')})`; }
				
				return placeholder;
			},
			container_classes()
			{
				if (!this.final_control) { return null; }
				
				return {
					"br-field-file--touched": this.userTouch_has,
					"br-field-file--error":   this.userTouch_has && this.lastValidationResultErrorMsgs_has,
				};
			},
			card_style()
			{
				if (this.final_displayMode_isAvatar)
				{
					return {
						width:    `${this.brFieldFileAttrs.filePreviewWidth}px`,
						height:   `${this.brFieldFileAttrs.filePreviewHeight}px`,
						overflow: "hidden"
					};
				}
				
				return {};
			},
			cardText_style()
			{
				return this.final_displayMode_isAvatar ? {padding:0} : {};
			},
			cloud_color()
			{
				if (this.drag_is) { return "primary"; }
				return this.items_canPutMore ? "secondary" : "grey";
			},
			drag_is() { return this.drag.state!==DRAG_STATE_OFF; },
			translation_noFiles() { return this.t_alt(`noFiles.${this.isMultiple?"multiple":"single"}`); },
			placeholder_show()
			{
				if (!this.final_displayMode_isAvatar) { return true; }
				return this.brFieldFileAttrs.filePreviewWidth*this.brFieldFileAttrs.filePreviewHeight >= PLACEHOLDER_MIN_AREA;
			},
			tiles_sheet_width()
			{
				const width = this.brFieldFileAttrs.filePreviewWidth;
				return width>TILES_MIN_WIDTH ? width : TILES_MIN_WIDTH;
			},
			tiles_toolbar_smallIcons()  { return this.tiles_sheet_width<=ITEM_TOOLBAR_MIN_WIDTH;                 },
			avatar_toolbar_smallIcons() { return this.brFieldFileAttrs.filePreviewWidth<=ITEM_TOOLBAR_MIN_WIDTH; },
			acceptMimePattern() { return this.final_control.acceptMimePattern; },
			final_readonly() { return this.final_control.isReadOnly || this.readonly; },
			required() { return this.final_control.required; },
			isMultiple() { return this.final_control.isMultiple; },
			hasOngoingAsyncTasks()              { return this.final_control.hasOngoingAsyncTasks;                }, //NOTE: When using prefixFilter, all components using this B_REST_FileControl instance will see the same val, but not really a prob
			lastValidationResultErrorMsgs_has() { return this.lastValidationResultErrorMsgs_translated.length>0; }, //NOTE: When using prefixFilter, this will show validation errors for -this- component, by using _reSync_lastValidationResultErrorMsgs()
			//Computed that are affected by prefixFilter
				items()
				{
					if (!this.final_control)      { return [];                       }
					if (this.prefixFilter===null) { return this.final_control.items; }
					return this.final_control.items.filter(loop_item => loop_item.fileInfo.baseNameWExt.indexOf(this.prefixFilter)===0);
				},
				items_count() { return this.items.length;                     },
				items_has()   { return this.items_count>0;                    },
				items_first() { return this.items_has ? this.items[0] : null; },
				hack_items_allStored_wCount()
				{
					//NOTE: Must include item count, otherwise when we delete and it saves automatically, we'd be before and now still isStored=true, so watch wouldn't fire
					for (const loop_item of this.items) { if(!loop_item.status_isStored) { return `${this.items_count}-no`; } }
					return `${this.items_count}-yes`;
				},
			//Computed that would break if prefixFilter was set
				maxFileCount()
				{
					const maxFileCount = this.final_control.maxFileCount;
					if (maxFileCount===null)      { return null; }
					this._assertNoPrefixFilter();   return maxFileCount;
				},
				maxSize()
				{
					const maxSize = this.final_control.maxSize;
					if (maxSize===null)           { return null; }
					this._assertNoPrefixFilter();   return maxSize;
				},
				maxSize_humanReadable()    { this._assertNoPrefixFilter(); return this.final_control.maxSize_humanReadable; },
				items_size_humanReadable() { this._assertNoPrefixFilter(); return this.final_control.items_size_humanReadable; },
				items_canPutMore()
				{
					if (this.final_control.items_canPutMore) { return true; }
					this._assertNoPrefixFilter();              return false;
				},
				zip_can() { return this.prefixFilter===null && this.final_control.zip_can; },
		},
		methods: {
			//GENERAL
				_throwField(msg) { B_REST_Utils.throwEx(`${this.final_field_quotedName}: ${msg}`); },
				_assertNoPrefixFilter() { if(this.prefixFilter){this._throwField(`Can't use this if prefixFilter is set`);} },
				//IMPORTANT: Setup stuff even if we start in RO, as it can change state during use
				async _checkInit()
				{
					if (this.final_control) { this._throwField(`Can't re-init component`); } //Otherwise would cause hell, ex w drag_flickerHelper_x()
					
					if (this.control) { this.final_control=this.control; }
					else if (this.field instanceof B_REST_ModelFields.File) { this.final_control=this.field.control; }
					else if (this.model instanceof B_REST_Model && B_REST_Utils.string_is(this.field))
					{
						const field = this.model.select(this.field); //Could throw
						if (!(field instanceof B_REST_ModelFields.File)) { this._throwField(`Must point to a B_REST_ModelField_File field`); }
						
						this.final_control = field.control;
					}
					else { this._throwField(`Must get any of control / field / model props to use this`); }
					
					if (this.prefixFilter && !this.isMultiple) { this._throwField(`prefixFilter can only be used when isMultiple`); } //The goal here is to allow fileList to have multiple purposes, whereas a single file can't have multiple files...
					
					this.final_field = this.final_control.ifModelFiles_modelField;  //Could end NULL
					
					//Some validation
					{
						if (!this.labelAbove) { this._throwField(`For now we only support labelAbove`); } //But it could eventually be inline, if single
						
						const displayMode = this.brFieldFileAttrs.displayMode;	
						if (displayMode)
						{
							if (!DISPLAY_MODES.includes(displayMode))                               { this._throwField(`Expects displayMode to be one of DISPLAY_MODE_x`);     }
							if (this.isMultiple && displayMode===DISPLAY_MODE_AVATAR) { this._throwField(`DISPLAY_MODE_AVATAR can only be used if !isMultiple`); }
						}		
					}
					
					//Setup drag flickering prevention
					this.drag_flickerHelper_start();
					
					//Setup del confirm prompt
					{
						this.confirmDelPrompt = new B_REST_Vuetify_Prompt(this.t_alt("confirmDeletion.title"), false/*isModal*/);
						
						this.confirmDelPrompt.actions = [
							new B_REST_Vuetify_Prompt_Action(CONFIRM_DEL_PROMPT_ACTIONS_CANCEL, this.t_alt("confirmDeletion.cancel"), null),
							null,
							new B_REST_Vuetify_Prompt_Action(CONFIRM_DEL_PROMPT_ACTIONS_PROCEED, this.t_alt("confirmDeletion.proceed"), "error"),
						];
					}
				},
					//NOTE: We can't do that in created() because $refs won't exist until mounted()
					_checkInit_setupEventListeners()
					{
						//Setup an event listener for when set files. For drag n drop, check drag_on_drop()
						this.$refs.hiddenFilePicker.addEventListener("change", async() =>
						{
							const addedFiles = this.final_control.items_parseDOMSelectionEvent_onInputFileChange(this.$refs.hiddenFilePicker, {injectFilePrefix:this.prefixFilter}); //Rets if we got valid files. Does async stuff, but doesn't wait for uploads
							this.userTouch_has = true;
							this._reSync_lastValidationResultErrorMsgs();
							if (addedFiles) { this._onAddedFilesEmitEvents(); }
							/*
							After the hidden file picker is used, set it back to empty right away, because if we later remove files and try to select them again,
							the file picker won't know it has changed (became empty) and the event won't ever be called again
							*/
							this.$refs.hiddenFilePicker.value = null;
						});
					},
						async _onAddedFilesEmitEvents()
						{
							this.userTouch_has = true;
							
							this.$emit("pendingUpload:start");
							await this.final_control.items_waitOngoingUploads(/*dieOnFailedTransfers*/false); //Shouldn't throw. NOTE: Doesn't patch changes to model; is only the case if component using this (normally a BrGenericFormBase) does something like @change="awaitUnsavedChangesSaved_filesOnly()"
							this.$emit("pendingUpload:done");
							this.$emit("change"); //Extra common event
						},
				openFilePicker()
				{
					if (this.items_canPutMore) { this.$refs.hiddenFilePicker.click(); }
				},
				items_get_baseNameWExt(item)
				{
					const baseNameWExt = item.fileInfo.baseNameWExt;
					return this.prefixFilter===null ? baseNameWExt : baseNameWExt.substr(this.prefixFilter.length);
				},
				items_get_size_humanReadable(item) { return item.fileInfo.size_humanReadable; },
				items_download_allZipped()
				{
					this._assertNoPrefixFilter();
					this.final_control.items_download_allZipped(null/*baseNameWExt*/, this.$refs.container); //Async
				},
				async items_toggle_remove_prompt(item) //Instance of B_REST_FileControlItem
				{
					//Check if we should confirm first
					if (item.status_isStored && !item.ifStored_toDelete)
					{
						this.confirmDelPrompt.body = this.t_alt("confirmDeletion.body", {baseNameWExt:this.items_get_baseNameWExt(item)});
						
						const selectedActionOrNull = await this.confirmDelPrompt.show();
						if (selectedActionOrNull!==CONFIRM_DEL_PROMPT_ACTIONS_PROCEED) { return; }
					}
					
					this.userTouch_has = true;
					this.final_control.items_toggle_remove(item); //NOTE: Doesn't patch changes to model; is only the case if component using this (normally a BrGenericFormBase) does something like @change="awaitUnsavedChangesSaved_filesOnly()"
					this._reSync_lastValidationResultErrorMsgs();
					this.$emit("delete");
					this.$emit("change"); //Extra common event
				},
				//NOTE: Copy of what is found in B_REST_FileControl; we have to duplicate this to make more sense when we work w prefixFilter
					_reSync_lastValidationResultErrorMsgs()
					{
						const errorMsgs = [];
						for (const loop_errorMsg of this.final_control.validation_getErrors(/*onlyOne*/false,/*includeAsyncCustomErrors*/true))
						{
							errorMsgs.push(this.prefixFilter===null ? loop_errorMsg : loop_errorMsg.substr(this.prefixFilter.length));
						}
						this.lastValidationResultErrorMsgs_translated = errorMsgs;
						this.final_control.validation_clearMsgs(); //Especially helpful for when using prefixFilter
					},
			//DRAG RELATED
				/*
				Drag state is tristate, because for some reason, @dragleave gets called everytime we move over child elems.
				Tried with "pointer-events:none" on child elems, but was worse
				*/
				drag_flickerHelper_start()
				{
					/*
					NOTE:
						If we'd be 100% sure that we'll always only have 1 setInterval() in this component and that we always only call drag_flickerHelper_stop() in beforeDestroy(),
						then we should let B_REST_VueApp_CreateCoreMixin auto take care of clearing intervals in its own beforeDestroy()
					*/
					this.drag.flickerHelper_interval_ptr = this.coreMixin_setInterval(() =>
					{
						if (this.drag.state===DRAG_STATE_LEAVING) { this.drag.state=DRAG_STATE_OFF; }
					}, this.drag.flickerHelper_interval_delay);
				},
				drag_flickerHelper_stop()
				{
					//NOTE: Check note in drag_flickerHelper_start()
					if (this.drag.flickerHelper_interval_ptr)
					{
						this.coreMixin_clearInterval(this.drag.flickerHelper_interval_ptr);
						this.drag.flickerHelper_interval_ptr = null;
					}
				},
				drag_on_enter() { if(this.items_canPutMore){this.drag.state=DRAG_STATE_ENTER;  } },
				drag_on_leave() { if(this.items_canPutMore){this.drag.state=DRAG_STATE_LEAVING;} },
				drag_on_drop($event)
				{
					if (this.items_canPutMore)
					{
						const addedFiles = this.final_control.items_parseDOMSelectionEvent_onDrop($event, {injectFilePrefix:this.prefixFilter}); //Rets if we got valid files. Does async stuff, but doesn't wait for uploads
						this.userTouch_has = true;
						this._reSync_lastValidationResultErrorMsgs();
						if (addedFiles)
						{
							this.drag.state = DRAG_STATE_OFF;
							this._onAddedFilesEmitEvents();
						}
					}
				},
		},
	};
	
	
	
	function validator_shortLabelUntilBreakpoint(val)
	{
		if (VUETIFY_BREAKPOINTS.includes(val)) { return true; }
		
		B_REST_Utils.throwEx(`Expected "shortLabelUntilBreakpoint" to be one of "${VUETIFY_BREAKPOINTS.join('", "')}"`);
		return false;
	}
	
</script>

<style scoped>
	
	.v-list-item :deep(.v-list-item__action) {
		flex-direction: unset !important;
		align-items: center;
	}
	
	.br-field-file {}
		.br-field-file--touched {}
			.br-field-file--touched .base-file-input__label .v-label {
				color: blue;
			}
			.br-field-file--touched .v-card {
				border: 2px solid blue;
			}
		.br-field-file--error {}
			.br-field-file--error .base-file-input__label .v-label {
				color: #ff5252;
			}
			.br-field-file--error .v-card {
				border: 2px solid #ff5252;
			}
		.br-field-file--tiles--toolbar-wrapper {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			z-index: 1;
			text-align: right;
			background: linear-gradient(180deg,black,transparent);
		}
	
</style>
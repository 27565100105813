
/*
Usage ex:
	Where we create like:
		const customErrorList = new B_REST_Model_CustomValidationErrorList("models.User.customValidationErrors");
	With loc like:
		{
			"models": {
				"User": {
					"customValidationErrors": {
						"pwdMismatch":       "Pwd & confirmation don't match",
						"firstName_tooLong": "Expected max {maxLength} chars, got {receivedLength}"
					}
				}
			}
		}
	Simple:
		if (pwd!==pwdConfirmation) { customErrorList.fast_add(   "pwdMismatch"); }
		else                       { customErrorList.fast_remove("pwdMismatch"); }
		Or using shorthand:
			customErrorList.fast_if(pwd!==pwdConfirmation, "pwdMismatch");
	With replacement tags:
		if (receivedLength>maxLength) { customErrorList.fast_add(   "firstName_tooLong", {receivedLength,maxLength}); }
		else                          { customErrorList.fast_remove("firstName_tooLong");                             }
		Or using shorthand:
			customErrorList.fast_if(receivedLength>maxLength, "firstName_tooLong", {receivedLength,maxLength});
Fast vs async:
	Used to differenciate errs that can be evaluated without causing any lag to the app, vs those that requires ex an API call
	Ex we could blur a pwd confirmation field and check -now- if it matches w the real pwd field,
	however we could blur a userName / email field and check for unicity only later when we click a save btn.
	We would disable the save btn if any "fast" validation fail, however for the async ones we should never disable btns and just redo the API calls JIT
	In other words, having 2 sep arrs allows to compare the nb of fast vs async errs and:
		-disable the save btn if we have at least 1 fast err
		-throw exception on save if we have async errs
*/

import B_REST_App_base from "../app/B_REST_App_base.js";



export default class B_REST_Model_CustomValidationErrorList
{
	_baseLocPath = null; //Ex "models.User.customValidationErrors"
	//Arrs of user friendly translated err msgs like {tag, msg}. Note that we don't use maps, as in reactive frameworks new props wouldn't be reactive
	_fast_errors  = [];
	_async_errors = [];
	
	
	constructor(baseLocPath)
	{
		this._baseLocPath = baseLocPath;
	}
	
	
	//Allow editions, for when it's for a field that has no bound model
	get baseLocPath()    { return this._baseLocPath; }
	set baseLocPath(val) { this._baseLocPath=val;    }
	
	
	get fast_errors()  { return this._fast_errors;  }
	get async_errors() { return this._async_errors; }
	
	
	get fast_has()  { return this._fast_errors.length >0; }
	get async_has() { return this._async_errors.length>0; }
	
	//Just rets the err msgs behind the tags
	fast_getMsgs()  { return this._x_getMsgs("_fast_errors");  }
	async_getMsgs() { return this._x_getMsgs("_async_errors"); }
	
	fast_add( tag,details=null) { this._x_add("_fast_errors", tag,details); }
	async_add(tag,details=null) { this._x_add("_async_errors",tag,details); }
	
	fast_remove(tag)  { this._x_remove("_fast_errors", tag); }
	async_remove(tag) { this._x_remove("_async_errors",tag); }
	
	fast_if( isInvalid,tag,details=null) { this._x_if("_fast_errors", isInvalid,tag,details); }
	async_if(isInvalid,tag,details=null) { this._x_if("_async_errors",isInvalid,tag,details); }
		
		_x_getMsgs(whichArr)
		{
			const msgs = [];
			for (const loop_error of this[whichArr]) { msgs.push(loop_error.msg); }
			return msgs;
		}
		_x_add(whichArr, tag, details=null)
		{
			const msg = B_REST_App_base.instance.t_custom(`${this._baseLocPath}.${tag}`, details);
			
			//If already existing, just update the msg
			const found = this[whichArr].find(loop_error => loop_error.tag===tag);
			if (found) { found.msg = msg;          }
			else       { this[whichArr].push({tag,msg}); }
		}
		_x_remove(whichArr, tag) { this[whichArr] = this[whichArr].filter(loop_error=>loop_error.tag!==tag); }
		_x_if(whichArr, isInvalid, tag, ifInvalid_details=null)
		{
			if (isInvalid) { this._x_add(   whichArr,tag,ifInvalid_details); }
			else           { this._x_remove(whichArr,tag);                   }
		}
};

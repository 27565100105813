<template>
	<div ref="container" class="br-field-file-preview" :style="{width:`${width}px`,height:`${height}px`}">
		<div v-if="img_isValid" class="br-field-file-preview--img-wrapper" :style="img_style" />
			<!--
				Started with the following, but there's a bug with imgs loading only once they appear in the screen:
					-Happens even if we set the eager attr
					-@load gets called even before the img is in view
					-So then img is revoked in advance
					-When we scroll and see it, browser crashes saying the object URL doesn't exist anymore
						<v-img v-if="img_isValid" :width="width" :height="height" :src="img.src" contain eager :lazy-src="img.lazySrc" @load="img_checkRevoke()" />
			-->
		<v-icon v-else :color="color" v-text="icon" :size="squareSize" dark />
	</div>
</template>

<script>
	
	import { B_REST_FileControlItem } from "../../../../classes";
	import B_REST_VueApp_CreateCoreMixin from "../../B_REST_VueApp_CreateCoreMixin.js";
	
	
	const COMPONENT_NAME         = "BrFieldFileItemPreview";
	const CORE_ALT_BASE_LOC_PATH = `app.components.${COMPONENT_NAME}`;
	
	const IMG_LAZY_SRC_GREY_16X16 = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAIAAACQkWg2AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAAZSURBVDhPY3hAIhjVQAwY1UAMGHQaHjwAAD9boB9HiJ0WAAAAAElFTkSuQmCC";
	const IMG_REVOKE_CHECK_DELAY  = 100; //Don't put 0 or even 1; should maybe even probably just let it destroy in component's beforeDestroy(). Not related to a Vuetify animation duration
	
	
	export default {
		name: COMPONENT_NAME,
		//This creates funcs like t(), and requires that component defines its name Vue prop. WARNING: Must define component's name too
		mixins: [
			B_REST_VueApp_CreateCoreMixin({
				coreAltBaseLocPath: CORE_ALT_BASE_LOC_PATH,
			}),
		],
		props: {
			item:       {type:B_REST_FileControlItem, required:true},
			width:      {type:Number,                 required:true},
			height:     {type:Number,                 required:true},
			squareSize: {type:Number,                 required:true},
		},
		data()
		{
			return {
				isInited: false,
				img: null, //Optional {lazySrc, isBroken, objectURL, mustRevoke, src}
			};
		},
		watch: {
			isOnline() { this._checkInit(); },
		},
		created() { this._checkInit(); },
		beforeDestroy()
		{
			if (this.img) { this.img_checkRevoke(); }
		},
		computed: {
			isOnline() { return this.$bREST.api.networkStatus_is_5_apiOk; },
			ext_lowerCase() { return this.item.fileInfo.ext?.toLowerCase() || ""; },
			icon()
			{
				if (this.img?.isBroken) { return "mdi-image-broken"; }
				
				//NOTE: We do this by ext and not mime, because some mime types are really long (like xlsx)
				switch (this.ext_lowerCase)
				{
					case "bmp": case "gif": case "jpeg": case "jfif": case "jpg": case "png": case "svg": return "mdi-file-image";
					case "pdf":                                                                           return "mdi-file-pdf-box"; //NOTE: For some reason, "mdi-file-pdf" got removed: https://dev.materialdesignicons.com/changelog
					case "doc": case "docx":                                                              return "mdi-file-word";
					case "xls": case "xlsx":                                                              return "mdi-file-excel";
					default:                                                                              return "mdi-file";
				}
			},
			color()
			{
				//NOTE: We do this by ext and not mime, because some mime types are really long (like xlsx)
				switch (this.ext_lowerCase)
				{
					case "bmp": case "gif": case "jpeg": case "jfif": case "jpg": case "png": case "svg": return "green";
					case "pdf":                                                                           return "red";
					case "doc": case "docx":                                                              return "blue";
					case "xls": case "xlsx":                                                              return "green";
					default:                                                                              return "grey";
				}
			},
			img_isValid()  { return this.img && !this.img.isBroken; },
			img_isBroken() { return this.img &&  this.img.isBroken; },
			img_style()
			{
				return {
					width:              `${this.width}px`,
					height:             `${this.height}px`,
					"background-image": `url(${this.img.src})`,
				};
			},
		},
		methods: {
			async _checkInit()
			{
				if (this.isInited || !this.isOnline) { return; }
				this.isInited = true;
				
				if (this.item.isImg)
				{
					this.img = {lazySrc:IMG_LAZY_SRC_GREY_16X16, isBroken:false, objectURL:null, mustRevoke:false, src:IMG_LAZY_SRC_GREY_16X16};
					
					try
					{
						//Check if we have a local copy of the img, to avoid having to do API calls
						if (this.item.ifNewX_domFilePtr)
						{
							this.img.mustRevoke = !this.item.ifNewX_domFilePtr.typeTag_is_objectURL; //Only revoke if we had to convert
							this.img.objectURL  = await this.item.ifNewX_domFilePtr.to_objectURL();
						}
						//Otherwise, make an API call, either to the actual img, or a resized version, if available
						else if (this.item.status_isStored)
						{
							this.img.objectURL  = await this.item.getPreviewImg();
							this.img.mustRevoke = true;
						}
						//If it's not stored on server and we don't even have a local version of it, then we're screwed
						else { this.img.isBroken = true; }
					}
					catch (e)
					{
						this.img.objectURL  = null;
						this.img.mustRevoke = false;
						this.img.isBroken   = true;
					}
					
					this.img.src = this.img.objectURL || IMG_LAZY_SRC_GREY_16X16;
					
					await this.coreMixin_sleep(IMG_REVOKE_CHECK_DELAY); //WARNING: Don't remove this, otherwise img won't have time to get sent in the img or background-img
					this.img_checkRevoke();
				}
			},
			img_checkRevoke()
			{
				if (this.img.mustRevoke)
				{
					this.$bREST.utils.files_objectURL_revoke(this.img.objectURL);
					
					this.img.mustRevoke = false;
					this.img.objectURL  = null;
				}
			},
		},
	};
	
</script>

<style scoped>
	
	.br-field-file-preview {
		position: relative;
		background-color: black;
	}
		.br-field-file-preview--img-wrapper {
			background-size: contain;
		    background-position: center;
		}
	
</style>
<template>
	<br-generic-list-base :derived-component="_self">
		<!-- 🚀↑app>modules>x>XList.vue>template>slots↑🚀 -->
	</br-generic-list-base>
</template>

<script>
	
	import { B_REST_FieldDescriptors }    from "@/bREST/core/classes";
	import { B_REST_Vuetify_GenericList } from "@/bREST/core/implementations/vue";
	
	
	
	export default {
		name: "clientList",
		mixins: B_REST_Vuetify_GenericList.createMixin({
			formComponent: () => import("./ClientForm.vue"),
			modelName: "Client",
			fromLoader: {
				apiBaseUrl: "/clients/",
			},
			cols: {
				name: {
					fieldNamePaths: "user.<toLabel>",
					toCellContent(col,model,defaultContent)
					{
						return model.select("user").toLabel();
					},
					style:{fromBreakpoint:"sm", align:B_REST_Vuetify_GenericList.Col.ALIGN_LEFT},
				},
				recoveryEmail: {
					fieldNamePaths: "user.recoveryEmail",
					style:{fromBreakpoint:"xs", align:B_REST_Vuetify_GenericList.Col.ALIGN_LEFT},
				},
				phone: {
					fieldNamePaths: "phone",
					style:{fromBreakpoint:"md", align:B_REST_Vuetify_GenericList.Col.ALIGN_LEFT},
				},
				address: {
					fieldNamePaths: "address",
					style:{fromBreakpoint:"md", align:B_REST_Vuetify_GenericList.Col.ALIGN_LEFT},
				},
				postalCode: {
					fieldNamePaths: "postalCode",
					style:{fromBreakpoint:"md", align:B_REST_Vuetify_GenericList.Col.ALIGN_LEFT},
				},
				
				/* 🚀↑app>modules>x>XList.vue>js>cols↑🚀 */
			},
			globalActions: {
				...B_REST_Vuetify_GenericList.GlobalAction.defineCommonAction_add({}),
				/* 🚀↑app>modules>x>XList.vue>js>globalActions↑🚀 */
			},
			row: {
				checkbox: {isEnabled:true},
				actions: {
					quickConnect: {
						click: {
							isEnabled(action,model) { return !!model.select("user.sudoHash").data; },
							async hook(action,model,isCtrlClickOrMiddleClick)
							{
								await this.$bREST.sudoIn(model.select("user.sudoHash").data);
								return true;
							},
						},
						icon: "mdi-account-switch",
					},
					...B_REST_Vuetify_GenericList.RowAction.defineCommonAction_edit({}),
					/* 🚀↑app>modules>x>XList.vue>js>rowActions↑🚀 */
				},
			},
			filters: {
				calc_flatSearch: {fieldNamePath:"calc_flatSearch"},
				recoveryEmail: {fieldNamePath:"user.recoveryEmail"},
				c28_wantsSpam: {fieldNamePath:"c28_wantsSpam"},
				calc_type: {fieldNamePath:"calc_type"},
				configSourceMarketing: {fieldNamePath:"configSourceMarketing_fk", items:"configSourceMarketingList"},
				main_franchisee_fk: {fieldNamePath:"main_franchisee_fk", picker:"franchiseeList", multiple:true},
				/* 🚀↑app>modules>x>XList.vue>js>filters↑🚀 */
					//IMPORTANT: If we add filters here, check to add indexes for that field
			},
		}),
	};
	
</script>
<template>
	<br-generic-form-base :derived-component="_self">
		
		<template #fields>
			<!-- Common things between client & staff -->
				<client-staff-form-common-stuff ref="clientStaffFormCommonStuff" :show-fields="showFields" :user-type="$bREST.consts.user_types.CLIENT" :model="model" :extra-data="extraData" :ifForContest_which="accountCreation_ifForContest_which" @change:pwdConfirmation="recalcPwdConfirmationValidation()" @change:recoveryEmailConfirmation="recalcRecoveryEmailConfirmationValidation()" />
			<!-- More client fields, that should be editable even by himself in accountCreation / myProfile -->
				<v-row>
					<v-col v-if="show_gender" cols="12" sm="6"> <br-field-db :model="model" field="gender" /> </v-col>
					<v-col v-if="show_configSourceMarketing" cols="12" sm="6">              <br-field-db :model="model" field="configSourceMarketing_fk" items="configSourceMarketingList" /> </v-col>
					<v-col v-if="main_franchisee_canSee" cols="12" sm="6">                  <br-field-db :model="model" field="main_franchisee_fk" items="filteredFranchiseeList" :label="main_franchisee_label"     :shortLabel="main_franchisee_label"     /> </v-col>
					<v-col v-if="reason_isAccountCreation || reason_isMyProfile" cols="12"> <br-field-db :model="model" field="c28_wantsSpam"         as="checkbox"               :label="c28_wantsSpam_label"       :shortLabel="c28_wantsSpam_label"       /> </v-col>
					<v-col v-if="show_emailPrefs_birthday" cols="12">                       <br-field-db :model="model" field="emailPrefs_birthday"   as="checkbox"               :label="emailPrefs_birthday_label" :shortLabel="emailPrefs_birthday_label" /> </v-col>
					<v-col v-if="isForContest" cols="12">
						<v-checkbox v-model="accountCreation_ifForContest_acceptPolicies" @change="recalcAccountCreation_ifForContest_acceptPoliciesValidation()" class="d-inline-block">
							<template #label>
								<label style="cursor:pointer">
									{{ t_contestPolicies("start") }}
									<a :href="$bREST.christmasWContestAndFreeVirtual_contest_policiesLink" target="_blank" download @click.stop="()=>{}" class="mx-1">{{ t_contestPolicies("link") }}</a>{{ t_contestPolicies("end") }}
								</label>
							</template>
						</v-checkbox>
					</v-col>
				</v-row>
			<!-- More client fields, for management -->
				<template v-if="reason_isModule">
					<v-row>
						<!-- 🚀↑app>modules>x>XForm.vue>template>fields↑🚀 -->
					</v-row>
					<v-row><v-col cols="12"> <br-field-db :model="model" field="user.isEnabled" as="checkbox" /> </v-col></v-row>
					
					<v-expansion-panels v-model="openPanels" accordion class="mt-8">
						<!-- Credit cards -->
						<v-expansion-panel v-if="showWallet"> <v-expansion-panel-header>{{ t("tabs.wallet.title") }}</v-expansion-panel-header> <v-expansion-panel-content :eager="LOAD_ON_MOUNT_TABS.wallet">
							<wallet :api-base-path="`/clients/${model.pk}/wallet`" merchantType="*" noTitle color="secondary" />
						</v-expansion-panel-content> </v-expansion-panel>
						<!-- Notes -->
						<v-expansion-panel> <v-expansion-panel-header>{{ t("tabs.notes.title") }}</v-expansion-panel-header> <v-expansion-panel-content :eager="LOAD_ON_MOUNT_TABS.notes">
							<x-note-list :note-list="model.select('notes')">
								<template #extraFields="{loop_model}">
									<v-col cols="12"> <br-field-db :model="loop_model" field="configClientNote_fk" items="configClientNoteList" /> </v-col>
								</template>
							</x-note-list>
						</v-expansion-panel-content> </v-expansion-panel>
						<!-- Contracts -->
						<v-expansion-panel v-if="revealContractsToClient"> <v-expansion-panel-header>{{ t("tabs.contracts.title") }}</v-expansion-panel-header> <v-expansion-panel-content :eager="LOAD_ON_MOUNT_TABS.contracts">
							<custom-btn color="primary" @click="contracts_new()">{{ t("tabs.contracts.new") }}</custom-btn>
							<custom-btn color="transparent|error" outlined @click="contracts_terminate()" class="ml-4">{{ t("tabs.contracts.terminate") }}</custom-btn>
							<br-generic-form-base-sub-model-list :form="self" route-names="client>clientSessionContract" :accordion="false" />
						</v-expansion-panel-content> </v-expansion-panel>
						<!-- Payments -->
						<v-expansion-panel v-if="revealContractsToClient"> <v-expansion-panel-header>{{ t("tabs.payments.title") }}</v-expansion-panel-header> <v-expansion-panel-content :eager="LOAD_ON_MOUNT_TABS.payments">
							<br-generic-form-base-sub-model-list :form="self" route-names="client>clientPayment" :accordion="false" />
						</v-expansion-panel-content> </v-expansion-panel>
						<!-- Referral history -->
						<v-expansion-panel v-if="showReferralHistory"> <v-expansion-panel-header>{{ t("tabs.referralHistory.title") }}</v-expansion-panel-header> <v-expansion-panel-content :eager="LOAD_ON_MOUNT_TABS.referralHistory">
							<br-generic-form-base-sub-model-list :form="self" route-names="client>clientReferralHistory" :accordion="false" />
						</v-expansion-panel-content> </v-expansion-panel>
						<!-- Tokens -->
						<v-expansion-panel> <v-expansion-panel-header>{{ t("tabs.tokens.title") }}</v-expansion-panel-header> <v-expansion-panel-content :eager="LOAD_ON_MOUNT_TABS.tokens">
							<client-form-token-summary :model="model" />
						</v-expansion-panel-content> </v-expansion-panel>
					</v-expansion-panels>
				</template>
			<!--
				Show contrats etc too if logged as client.
				IMPORTANT:
					Don't merge this w the above tabs code when in management, because management has action btns & uses a <br-generic-form-base-sub-model-list>,
					while in myProfile we use a normal list but w a diff apiBaseUrl
			-->
				<template v-if="reason_isMyProfile && $bREST.user_isAuth">
					<v-expansion-panels v-model="openPanels" accordion class="mt-8">
						<!-- Credit cards -->
						<v-expansion-panel v-if="showWallet"> <v-expansion-panel-header>{{ t("tabs.wallet.title") }}</v-expansion-panel-header> <v-expansion-panel-content :eager="LOAD_ON_MOUNT_TABS.wallet">
							<wallet api-base-path="/clients/profile/wallet" merchantType="*" noTitle color="secondary" />
						</v-expansion-panel-content> </v-expansion-panel>
						<!-- Contracts -->
						<v-expansion-panel v-if="revealContractsToClient"> <v-expansion-panel-header>{{ t("tabs.contracts.title") }}</v-expansion-panel-header> <v-expansion-panel-content :eager="LOAD_ON_MOUNT_TABS.contracts">
							<client-session-contract-list :from-loader="{apiBaseUrl:'/clients/profile/contracts'}" :show-title="false" />
						</v-expansion-panel-content> </v-expansion-panel>
						<!-- Payments -->
						<v-expansion-panel v-if="revealContractsToClient"> <v-expansion-panel-header>{{ t("tabs.payments.title") }}</v-expansion-panel-header> <v-expansion-panel-content :eager="LOAD_ON_MOUNT_TABS.payments">
							<client-payment-list :from-loader="{apiBaseUrl:'/clients/profile/payments'}" :show-title="false" />
						</v-expansion-panel-content> </v-expansion-panel>
						<!-- Referral history -->
						<v-expansion-panel v-if="showReferralHistory"> <v-expansion-panel-header>{{ t("tabs.referralHistory.title") }}</v-expansion-panel-header> <v-expansion-panel-content :eager="LOAD_ON_MOUNT_TABS.referralHistory">
							<client-referral-history :from-loader="{apiBaseUrl:'/clients/profile/referralHistory'}" :show-title="false" />
						</v-expansion-panel-content> </v-expansion-panel>
						<!-- Tokens -->
						<v-expansion-panel> <v-expansion-panel-header>{{ t("tabs.tokens.title") }}</v-expansion-panel-header> <v-expansion-panel-content :eager="LOAD_ON_MOUNT_TABS.tokens">
							<client-form-token-summary :model="model" />
						</v-expansion-panel-content> </v-expansion-panel>
					</v-expansion-panels>
				</template>
		</template>
		
		<template v-if="reason_isAccountCreation" #actions>
			<v-row>
				<slot name="actions" /> <!-- Allow adding extra shits -->
				<v-col cols="12" class="text-center">
					<custom-btn color="success" :disabled="accountCreation_dumbClients?false:!shouldSavingBeEnabled" class="br-generic-form-base-actions-save mx-auto" @click="reason_isAccountCreation_attemptSave()">{{ accountCreation_saveLabel ?? t("reason_isAccountCreation.save.label") }}</custom-btn>
				</v-col>
			</v-row>
		</template>
		
		<!-- 🚀↑app>modules>x>XForm.vue>template>slots↑🚀 -->
		
	</br-generic-form-base>
</template>

<script>
	
	import { B_REST_App_base } from "@/bREST/core/classes";
	import { B_REST_Vuetify_GenericForm } from "@/bREST/core/implementations/vue";
	import RegFlow from "@/custom/components/regFlow/RegFlow.js";
	
	//If when model is new-ed or done loading (existing pk), if we should also load the tabs content or defer until we open them for the 1st time
	const LOAD_ON_MOUNT_TABS = {
		wallet:          false,
		notes:           false,
		contracts:       false,
		payments:        false,
		referralHistory: false,
		tokens:          false,
	};
	
	
	
	export default {
		name: "clientForm",
		components: {
			ClientStaffFormCommonStuff: () => import("@/custom/components/ClientStaffFormCommonStuff.vue"),
			ClientSessionContractList:  () => import("@/custom/routerViews/modules/clientSessionContract/ClientSessionContractList.vue"),
			ClientPaymentList:          () => import("@/custom/routerViews/modules/clientPayment/ClientPaymentList.vue"),
			ClientReferralHistory:      () => import("@/custom/routerViews/modules/clientReferralHistory/ClientReferralHistoryList.vue"),
			XNoteList:                  () => import("@/custom/components/XNoteList.vue"),
			ClientFormTokenSummary:     () => import("./ClientFormTokenSummary.vue"),
			Wallet:                     () => import("@/custom/components/wallet/Wallet.vue"),
			/* 🚀↑app>modules>x>XForm.vue>js>components↑🚀 */
		},
		mixins: B_REST_Vuetify_GenericForm.createMixin({
			modelName:          "Client",
			apiBaseUrl:         "/clients/",
			showValidationErrs: true,    //🚀❓ If we want red err msgs near the save btn at the bottom of the form ❓🚀
			showSkeletonLoader: true,    //🚀❓ While we load an existing record, do we want a grey "skeleton" overlay ? ❓🚀
		  //autoUpdateInterval: 2000,    //🚀❓ If we want that blurring fields auto save modifs every X msecs ❓🚀
			requiredFields:     "<all>|user(<dbOnly>|pwd_dbHash|sudoHash)|notes(<dbOnly>|authorUser.<toLabel>)", //🚀❓ Check server's ModelOptions_base.php docs for "fieldNamePath" possibilities ❓🚀
									//IMPORTANT: Server RouteParser_Clients::PROFILE_ALLOWED_FIELD_NAME_PATHS & frontend's ClientForm.vue::requiredFields must match
			todos: [
				//🚀❓ Arr of {isDone,isBug,text} that will appear automatically at the top of the form ❓🚀
			],
			async modelReady()
			{
				/*
					🚀❓
						When a new record, called right on component mount.
						When an existing record, called after it's done loading & afterLoad() hook done
						Usage ex, where we also adjust for possible cases where we'd get a parent pk
							if (this.model.isNew)
							{
								//For when route is like /citizens/:citizen/animals/:pkTag, or we're from a BrGenericListBase::openFormInVDialog(), etc. Can get better cue parent_modelName or parent_routeName. Check props docs
								if (this.parent_pkTag) { this.model.select("citizen_fk").val=this.parent_pkTag; }
							}
					🚀❓
				*/
				
				if (this.model.isNew && this.regFlow) { this.model.select("main_franchisee_fk").val=this.regFlow.franchisee_fk; }
				
				if (this.isForContest)
				{
					this.model.extraData_api = {ifForContest_which:this.accountCreation_ifForContest_which}; //So when we create we can link to contest
					this.recalcAccountCreation_ifForContest_acceptPoliciesValidation();
				}
			},
			async beforeLoad(request)
			{
				if (this.reason_isMyProfile)
				{
					request.reConstruct(request.constructor.METHOD_GET, "clients/profile");
					request.needsAccessToken = true;
				}
			},
			async afterLoad(response,models) { }, //🚀❓ When an existing record, a hook where we still have the API B_REST_Response available to pimp the model. Called before modelReady() ❓🚀
			//🚀❓ Called at the beginning of awaitUnsavedChangesSaved_x() to check if it's ok to save. Can also be called manually. Should put customErrorList.x_add/x_if() here. Check BrGenericFormBase.vue::customErrors_x() docs or how we use it ex in UserForm.vue or MyProfile.vue ❓🚀
			async customValidator()
			{
				//🚀❓ User name & email unicity validation + email verification security code are taken care of automatically in B_REST_App_base::_commonDefs_setupDescriptorHooks()
				
				this.recalcPwdConfirmationValidation();
				this.recalcRecoveryEmailConfirmationValidation();
				this.recalcAccountCreation_ifForContest_acceptPoliciesValidation();
			},
			//🚀❓ When we want to save, a hook so we can pimp the API B_REST_Request_x that will be sent to the server ❓🚀
			async beforeSave(request,model)
			{
				if (this.reason_isAccountCreation)
				{
					request.reConstruct(request.constructor.METHOD_POST, "clients/profile");
					request.needsAccessToken_setDont();
				}
				else if (this.reason_isMyProfile)
				{
					request.reConstruct(request.constructor.METHOD_PATCH, "clients/profile");
					request.needsAccessToken = true;
				}
				
				if (this.regFlow) { RegFlow.apiCalls_injectRegFlowHash(request,this.regFlow); } //Check server's RouteParser_CPA_base::_setSpecs_fromRequest_checkHasRegFlowHash_setClient_injectForceReload() docs for flow of info between frontend & server for that
			},
			//🚀❓ Like afterLoad(), we just saved and we still have access to the API B_REST_Response, to do extra stuff ❓🚀
			async afterSave(response,model,isSuccess,wasNew)
			{
				const customData_regFlow = response.coreProps_customData?.regFlow ?? null;
				if (this.regFlow && customData_regFlow) { this.regFlow.updateFromExternalAPICall(customData_regFlow); }
			},
		}),
		props: {
			showFields:                         {type:String,  default:"all"}, //min|most|all
			accountCreation_saveLabel:          {type:String,  default:null},  //Alt save btn label for when we're in reason_isAccountCreation
			accountCreation_dumbClients:        {type:Boolean, default:true},
			accountCreation_hideBirthday:       {type:Boolean, default:false},
			accountCreation_ifForContest_which: {type:String,  default:null},  //One of Model_ClientContestEntry::WHICH_x
		},
		watch: {
			isForContest_hasUnicityHell() { this.$emit("isForContest_hasUnicityHell",this.isForContest_hasUnicityHell); },
		},
		data()
		{
			return {
				LOAD_ON_MOUNT_TABS,
				dataSets: {
					/* 🚀↑app>modules>x>XForm.vue>js>dataSets↑🚀 */
				},
				age: null,
				openPanels: [],
				accountCreation_ifForContest_acceptPolicies: false,
			};
		},
		computed: {
			reason_isAccountCreation()   { return  this.extraData?.reason==="accountCreation"; },
			reason_isMyProfile()         { return  this.extraData?.reason==="myProfile";       },
			reason_isModule()            { return !this.extraData?.reason;                     },
			regFlow()                    { return this.extraData?.regFlow ?? null;             },  //Check server's RouteParser_CPA_base::_setSpecs_fromRequest_checkHasRegFlowHash_setClient_injectForceReload() docs for flow of info between frontend & server for that
			isSelf()                     { return this.reason_isAccountCreation || this.reason_isMyProfile || this.model.select("user_fk").val===this.$bREST.user_pk; },
			main_franchisee_canSee()     { return (this.reason_isAccountCreation&&!this.showFields_min) || (this.reason_isModule&&this.model_isNew) || this.$bREST.roles_isAnyRoleAdmin || this.isForContest; },
			main_franchisee_label()      { return  this.reason_isAccountCreation ? `${this.t("reason_isAccountCreation.main_franchisee.label")} *`             : null; },
			c28_wantsSpam_label()        { return !this.reason_isModule          ? this.t("reason_isAccountCreation_or_isMyProfile.c28_wantsSpam.label")       : null; },
			emailPrefs_birthday_label()  { return !this.reason_isModule          ? this.t("reason_isAccountCreation_or_isMyProfile.emailPrefs_birthday.label") : null; },
			showFields_min()             { return this.showFields==="min";  },
			showFields_most()            { return this.showFields==="most"; },
			showFields_all()             { return this.showFields==="all";  },
			show_gender()                { return this.showFields_all;      },
			show_configSourceMarketing() { return !this.showFields_min;     },
			showWallet()                 { return this.$bREST.businessConfig.custom.moneris_has;             },
			showReferralHistory()        { return this.$bREST.businessConfig.custom.referral_enabled;        },
			show_emailPrefs_birthday()   { return !this.accountCreation_hideBirthday;                        },
			revealContractsToClient()    { return this.$bREST.businessConfig.custom.revealContractsToClient; },
			isForContest()               { return this.accountCreation_ifForContest_which!==null;            },
			isForContest_hasUnicityHell()
			{
				if (!this.model) { return false; }
				return this.model.select("user.userName").validation_custom_errorList.async_has || this.model.select("user.recoveryEmail").validation_custom_errorList.async_has;
			},
		},
		methods: {
			recalcAccountCreation_ifForContest_acceptPoliciesValidation()
			{
				if (!this.isForContest) { return; }
				this.customErrorList.fast_if(!this.accountCreation_ifForContest_acceptPolicies, "contest_acceptPolicies");
			},
			recalcPwdConfirmationValidation()
			{
				//🚀❓ Calls a func in BrFieldDbConfirmation hosted in common base form, to check if we should register a custom err in the form. Check its docs + BrGenericFormBase::shouldSavingBeEnabled() docs
				this.customErrorList.fast_if(this.$refs.clientStaffFormCommonStuff.pwdConfirmation_checkShouldWarn(), "pwdMismatch");
					//WARNING: $refs aren't reactive, so don't end up using this in some computed - use $emit instead
			},
			recalcRecoveryEmailConfirmationValidation()
			{
				//🚀❓ Calls a func in BrFieldDbConfirmation hosted in common base form, to check if we should register a custom err in the form. Check its docs + BrGenericFormBase::shouldSavingBeEnabled() docs
				this.customErrorList.fast_if(this.$refs.clientStaffFormCommonStuff.recoveryEmailConfirmation_checkShouldWarn(), "recoveryEmailMismatch");
					//WARNING: $refs aren't reactive, so don't end up using this in some computed - use $emit instead
			},
			contracts_new()       { this.$refs.clientStaffFormCommonStuff.quickConnect(); },
			contracts_terminate() { this.$refs.clientStaffFormCommonStuff.quickConnect(); },
			reason_isAccountCreation_attemptSave()
			{
				this.model.userTouch_toggleAllFields(true);
				this.awaitUnsavedChangesSaved_alwaysResolve();
			},
			t_contestPolicies(subLocPath) { return this.t(`contestPolicies.${subLocPath}`, {contestName:this.$bREST.christmasWContestAndFreeVirtual_contest_name}); },
		},
	};
	
</script>
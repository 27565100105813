<template>
	<br-generic-list-base :derived-component="_self">
		<!-- 🚀↑app>modules>x>XList.vue>template>slots↑🚀 -->
	</br-generic-list-base>
</template>

<script>
	
	import { B_REST_FieldDescriptors }    from "@/bREST/core/classes";
	import { B_REST_Vuetify_GenericList } from "@/bREST/core/implementations/vue";
	
	
	
	export default {
		name: "sessionList",
		mixins: B_REST_Vuetify_GenericList.createMixin({
			formComponent: () => import("./SessionForm.vue"),
			modelName: "Session",
			fromLoader: {
				apiBaseUrl: "/sessions/",
			},
			cols: {
				name: {
					fieldNamePaths: "name",
					style:{fromBreakpoint:"xs", align:B_REST_Vuetify_GenericList.Col.ALIGN_LEFT},
				},
				d_priorityReg_from: {
					fieldNamePaths: "d_priorityReg_from",
					style:{fromBreakpoint:"xs", align:B_REST_Vuetify_GenericList.Col.ALIGN_LEFT},
					toCellContent(col,model,defaultContent) { return this.formatModelDTField(model,"d_priorityReg_from"); },
				},
				d_genReg_from: {
					fieldNamePaths: "d_genReg_from",
					style:{fromBreakpoint:"xs", align:B_REST_Vuetify_GenericList.Col.ALIGN_LEFT},
					toCellContent(col,model,defaultContent) { return this.formatModelDTField(model,"d_genReg_from"); },
				},
				d_events_from: {
					fieldNamePaths: "d_events_from",
					style:{fromBreakpoint:"xs", align:B_REST_Vuetify_GenericList.Col.ALIGN_LEFT},
				},
				d_events_to: {
					fieldNamePaths: "d_events_to",
					style:{fromBreakpoint:"xs", align:B_REST_Vuetify_GenericList.Col.ALIGN_LEFT},
				},
				/* 🚀↑app>modules>x>XList.vue>js>cols↑🚀 */
			},
			globalActions: {
				...B_REST_Vuetify_GenericList.GlobalAction.defineCommonAction_add({}),
				/* 🚀↑app>modules>x>XList.vue>js>globalActions↑🚀 */
			},
			row: {
				checkbox: {isEnabled:true},
				actions: {
					...B_REST_Vuetify_GenericList.RowAction.defineCommonAction_edit({}),
					/* 🚀↑app>modules>x>XList.vue>js>rowActions↑🚀 */
				},
			},
			filters: {
				d_events_from: {fieldNamePath:"d_events_from"},
				d_events_to: {fieldNamePath:"d_events_to"},
				d_priorityReg_from: {fieldNamePath:"d_priorityReg_from"},
				d_genReg_from: {fieldNamePath:"d_genReg_from"},
				isAvailable: {fieldNamePath:"isAvailable"},
				/* 🚀↑app>modules>x>XList.vue>js>filters↑🚀 */
					//IMPORTANT: If we add filters here, check to add indexes for that field
			},
		}),
		methods: {
			formatModelDTField(model, fieldName)
			{
				const field = model.select(fieldName);
				
				return field.isNotEmpty_andValid ? field.val.substr(0,10) : null;
			},
		},
	};
	
</script>

import axios from "axios";

import B_REST_Utils      from "../B_REST_Utils.js";
import B_REST_DOMFilePtr from "../files/B_REST_DOMFilePtr.js";
import B_REST_CallStats  from "./B_REST_CallStats.js";
import B_REST_Response   from "./B_REST_Response.js";
import {B_REST_Request_base, B_REST_Request_GET,B_REST_Request_GET_File,B_REST_Request_POST,B_REST_Request_POST_File,B_REST_Request_POST_Multipart,B_REST_Request_PUT,B_REST_Request_PUT_Multipart,B_REST_Request_PATCH,B_REST_Request_PATCH_Multipart,B_REST_Request_DELETE} from "./B_REST_Request.js";

/*
**************************************
******** CORS RELATED - START ********
**************************************
	As long as we put this in PHP:
		header("Access-Control-Allow-Origin: *");
		header("Access-Control-Allow-Headers: Authorization, Cache-Control, Accept, Content-Type, Accept-Language");
		header("Access-Control-Expose-Headers: *");
		+ When PHP receives a preflight "OPTIONS" method, add this:
			header("Access-Control-Allow-Methods: GET,POST,PUT,PATCH,DELETE,OPTIONS");
	... we shouldn't need to use these in vue.config.js:
			module.exports = {
				devServer: {
					disableHostCheck: true,
					proxy: "https://flagfranchise-dev.keybook.com", -> or actually, should put something in the ENV file
				},
			};
************************************
******** CORS RELATED - END ********
************************************
*/



export default class B_REST_API
{
	static get CALL_STATS_FALLBACK_SHORT_NAME() { return "_fallback_"; }
	
	static get DEFAULT_MAX_PARALLEL_CONNECTIONS() { return 4; }
	
	static get HEADERS_B_REST_IS()                      { return "x-b-rest-is";              }
	static get HEADERS_B_REST_TIMEZONE()                { return "x-b-rest-tz";              }
	static get HEADERS_B_REST_LAST_SUCCESSFUL_CALL_DT() { return "x-b-rest-last-success-dt"; }
	static get HEADERS_B_REST_EXTRA_HEADERS()           { return "x-b-rest-extra-headers";   }
		//IMPORTANT: If we add stuff here, we must also add them in server's HttpUtils::_B_REST_CORS_HEADERS
	
	static get NETWORK_STATUS_1_DISABLED_OR_OUT_OF_RANGE() { return "1_disabledOrOutOfRange"; } //Disabled or out of range to a router / cell tower
	static get NETWORK_STATUS_2_LAN()                      { return "2_lan";                  } //At least connected to a router / cell tower, but not reaching cloud
	static get NETWORK_STATUS_3_PINGED_GOOGLE()            { return "3_pingedGoogle";         } //Can at least reach google etc, but didn't try talking to API yet
	static get NETWORK_STATUS_4_API_BAD_GATEWAY()          { return "4_apiBadGateway";        } //Can at least reach google etc, but can't talk to the API for some reason
	static get NETWORK_STATUS_5_API_OK()                   { return "5_apiOk";                } //All OK
				static _NETWORK_STATUSES_WATCH_PROPS = {
					"1_disabledOrOutOfRange": {checkLevel:"api", throttleFreqMultiple:2}, //Meaning NETWORK_STATUSES_WATCH_BASE_FREQ_MSECS*throttleFreqMultiple
					"2_lan":                  {checkLevel:"api", throttleFreqMultiple:5},
					"3_pingedGoogle":         {checkLevel:"api", throttleFreqMultiple:5}, //This is just a default status that should happen only at the beginning, but if boot call fails, we could try to resume...
					"4_apiBadGateway":        {checkLevel:"api", throttleFreqMultiple:5},
					"5_apiOk":                {checkLevel:"lan", throttleFreqMultiple:1},
				};
				static get NETWORK_STATUSES_WATCH_BASE_FREQ_MSECS() { return 1000; }
	
	
	//Config stuff
		_baseURL                        = null;  //Ex "https://flag-dev.keybook.com/api". Don't put trailing "/" at the end
		_uptime_path_raw                = null;  //Of a GET endpoint in API to know if server is up. Expects a 204
		_networkStatus                  = B_REST_API.NETWORK_STATUS_3_PINGED_GOOGLE; //IMPORTANT: Don't put NETWORK_STATUS_5_API_OK, because we can't tell for sure until the boot call is done
		_networkStatus_onChange_handler = null;  //Optional func as (). When we do API calls (and optionally in the net connection check interval below), a check is made to see if we have net connection. This gets triggered when it -changes-
		_callStats_byShortName          = {};    //Map of request's shortName => instance of B_REST_CallStats, for calls where B_REST_Request_base::shortName is set
		_callStats_summary              = null;  //Instance of B_REST_CallStats
		_rejectUnsuccessfulCalls        = true;  //Whether call() should resolve or reject, when !B_REST_Response.isSuccess
		_log_handler                    = null;  //Optional func as (msg, isError, details=null), called in various places
		_tweakRequest_async_handler     = null;  //Optional async func as (request), ex to add more data / QSA / headers to the request. Call won't get fired until the handler returns
		_tweakResponse_async_handler    = null;  //Optional async func as (response), called right before call() either resolves / rejects, to either tweak or do other async things right at that time. Must complete in order to finish the call
		_afterCall_general_handler      = null;  //Optional func as (response), called at the end of call(), no matter the B_REST_Response instance is successful or not
		_timeout_msecs                  = null;  //Optional, and should prolly not be set
		_maxParallelConnections         = null;  //NULL = no limit, otherwise queue when we've fired more than X parallel calls to Axios that haven't succeeded/failed yet. Check "PARALLEL CONNECTIONS RELATED" section
	//Client stuff
		_accessToken_public  = null;
		_accessToken_private = null;  //Usually, only serves when refreshing access tokens (if implemented)
		_lang                = null;
	//Logic stuff
		_networkStatus_watch_intervalPtr    = null;
		_networkStatus_watch_lastTime_u     = 0;    //Date.now()
		_networkStatus_update_promise       = null;
		_parallelConnections_ongoingCount   = 0;    //Nb of those that we did let go and that they haven't completed yet
		_parallelConnections_queuedRequests = [];   //Arr of {request, payloadSize, weight, start(Promise resolver)}
		_lastSuccessfulCall_dt              = null; //Date instance
	
	
	constructor(options={})
	{
		options = B_REST_Utils.object_hasValidStruct_assert(options, {
			baseURL:                        {accept:[String],   required:true},
			uptime_path_raw:                {accept:[String],   required:true},
			networkStatus_onChange_handler: {accept:[Function], default:null},
			rejectUnsuccessfulCalls:        {accept:[Boolean],  default:true},
			log_handler:                    {accept:[Function], default:null},
			tweakRequest_async_handler:     {accept:[Function], default:null},
			tweakResponse_async_handler:    {accept:[Function], default:null},
			afterCall_general_handler:      {accept:[Function], default:null},
			timeout_msecs:                  {accept:[Number],   default:null},
			maxParallelConnections:         {accept:[Number],   default:B_REST_API.DEFAULT_MAX_PARALLEL_CONNECTIONS},
		}, "API");
		
		B_REST_Utils.assert_formData_support();
		if (!options.baseURL) { B_REST_Utils.throwEx("baseURL empty. Check class docs for constructor options"); }
		
		this._baseURL                        = options.baseURL;
		this._uptime_path_raw                = options.uptime_path_raw;
		this._networkStatus_onChange_handler = options.networkStatus_onChange_handler;
		this._rejectUnsuccessfulCalls        = options.rejectUnsuccessfulCalls;
		this._log_handler                    = options.log_handler;
		this._tweakRequest_async_handler     = options.tweakRequest_async_handler;
		this._tweakResponse_async_handler    = options.tweakResponse_async_handler;
		this._afterCall_general_handler      = options.afterCall_general_handler;
		this._timeout_msecs                  = options.timeout_msecs;
		this._maxParallelConnections         = options.maxParallelConnections;
		
		this._callStats_summary     = new B_REST_CallStats();
		this._callStats_byShortName = {};
		
		//Setup a task to intelligently check how the network is doing, without spamming google nor the API for no reason when all is going well
		this._networkStatus_watch_intervalPtr = setInterval(() =>
		{
			if (this._callStats_summary.pending_has) { return; } //When we're already busy doing API calls, don't try to check this at the same time, as we're already updating it via call()::innerAxiosCall()
			const watchProps = B_REST_API._NETWORK_STATUSES_WATCH_PROPS[this._networkStatus] ?? B_REST_Utils.throwEx(`Unexpected networkStatus "${this._networkStatus}"`);
			const now_u      = Date.now();
			const nextTime_u = this._networkStatus_watch_lastTime_u + B_REST_API.NETWORK_STATUSES_WATCH_BASE_FREQ_MSECS*watchProps.throttleFreqMultiple;
			if (now_u<nextTime_u) { return; }
			this._networkStatus_watch_lastTime_u = now_u;
			this.networkStatus_update(watchProps.checkLevel, /*statusIsProlly_4_apiBadGateway*/false); //NOTE: Skipped if already ongoing
		}, B_REST_API.NETWORK_STATUSES_WATCH_BASE_FREQ_MSECS);
	}
		destroy()
		{
			clearInterval(this._networkStatus_watch_intervalPtr);
		}
	
	
	
	//NET CONNECTION STATUS
		get networkStatus()                           { return this._networkStatus;                                                        }
		get networkStatus_is_1_disabledOrOutOfRange() { return this._networkStatus===B_REST_API.NETWORK_STATUS_1_DISABLED_OR_OUT_OF_RANGE; }
		get networkStatus_is_2_lan()                  { return this._networkStatus===B_REST_API.NETWORK_STATUS_2_LAN;                      }
		get networkStatus_is_3_pingedGoogle()         { return this._networkStatus===B_REST_API.NETWORK_STATUS_3_PINGED_GOOGLE;            }
		get networkStatus_is_4_apiBadGateway()        { return this._networkStatus===B_REST_API.NETWORK_STATUS_4_API_BAD_GATEWAY;          }
		get networkStatus_is_5_apiOk()                { return this._networkStatus===B_REST_API.NETWORK_STATUS_5_API_OK;                   }
			/*
			Where checkLevel:
				api:   Checks if we can ping the API server, or ping google, or at least reach LAN
				cloud: Checks if we can ping google, or at least reach LAN
				lan:   Just checks if we can reach LAN
			Note that its status is also updated in call()::innerAxiosCall() via successful & failure responses, and networkStatus_watch_intervalPtr
			WARNING: Don't set checkLevel to "api" from within a call or we'll get an infinite loop
			*/
			async networkStatus_update(checkLevel, statusIsProlly_4_apiBadGateway=false)
			{
				if (this._networkStatus_update_promise) { return this._networkStatus_update_promise; }
				let end = null;
				this._networkStatus_update_promise = new Promise((s,f) =>
				{
					end = (status) =>
					{
						this._networkStatus_set(status);
						s();
						this._networkStatus_update_promise = null;
					};
				});
				
				if (!B_REST_Utils.network_canReach_lan()) { return end(B_REST_API.NETWORK_STATUS_1_DISABLED_OR_OUT_OF_RANGE); }
				
				switch (checkLevel)
				{
					case "lan":
						return end(this._networkStatus);
					case "api":
						//IMPORTANT: Check docs about infinite loops
						const request = new this.GET(this._uptime_path_raw);
						request.needsAccessToken_setDont();
						request.expectsContentType_empty();
						request.offline_retry_count = 0;
						try
						{
							await this.call(request);
							return end(B_REST_API.NETWORK_STATUS_5_API_OK);
						}
						catch (response)
						{
							B_REST_Utils.console_error(`Caught err while doing uptime call`,response);
							statusIsProlly_4_apiBadGateway = true;
						}
						//IMPORTANT: No break nor return here
					case "cloud":
						if (!await B_REST_Utils.network_canReach_cloud()) { return end(B_REST_API.NETWORK_STATUS_2_LAN); }
						return end(statusIsProlly_4_apiBadGateway ? B_REST_API.NETWORK_STATUS_4_API_BAD_GATEWAY : B_REST_API.NETWORK_STATUS_3_PINGED_GOOGLE);
					default: B_REST_Utils.throwEx(`Unexpected checkLevel "${checkLevel}"`);
				}
			}
				_networkStatus_set(status)
				{
					if (status===this._networkStatus) { return; }
					this._networkStatus = status;
					this._fireHandler("_networkStatus_onChange_handler");
				}
	
	
	
	//LOGS
		_log(msg, isError, details=null)
		{
			this._fireHandler("_log_handler", msg,isError,details);
		}
	
	
	
	//CALL STATS
		get callStats_summary_pending_has() { return this._callStats_summary.pending_has; }
		get callStats_summary_debug()       { return this._callStats_summary.debug;       }
		get callStats_summary_debugAll()    { console.table(this._callStats_byShortName); }
		callStats_byShortName_pending_has(shortName) { return this._callStats_byShortName[shortName]?.pending_has ?? false; }
	
	
	
	//CLIENT STUFF
		accessToken_set(accessToken_public, accessToken_private)
		{
			this._accessToken_public  = accessToken_public;
			this._accessToken_private = accessToken_private;
		}
		accessToken_clear()
		{
			this._accessToken_public  = null;
			this._accessToken_private = null;
		}
		get accessToken_public()  { return this._accessToken_public;   }
		get accessToken_isValid() { return !!this._accessToken_public; }
		get lang()                { return this._lang;                 }
		set lang(val)             { this._lang=val;                    }
	
	
	
	//OTHER GETTERS
		get baseURL()                 { return this._baseURL;                 }
		get rejectUnsuccessfulCalls() { return this._rejectUnsuccessfulCalls; }
		get timeout_msecs()           { return this._timeout_msecs;           }
		get maxParallelConnections()  { return this._maxParallelConnections;  }
	
	
	
	//x_HANDLER RELATED
		/*
		Since user can define custom handler for several things, if they throw exceptions, then the flow of B_REST_API will completely get broken, so this isolates the errs
		Rets NULL if the handler isn't defined
		Usage ex:
			this._fireHandler("_networkStatus_onChange_handler")
			this._fireHandler("_log_handler",                 msg,isError,details)
			this._fireHandler("_tweakRequest_async_handler",  request)
			this._fireHandler("_tweakResponse_async_handler", response)
			this._fireHandler("_afterCall_general_handler",   response)
		WARNING: Eats errors and converts them into B_REST_Utils::console_error() logs, so code execution continues anyways
		*/
		async _fireHandler()
		{
			const handlerVarName = arguments[0];
			
			//For logs, make sure we always know about errs, even if the user doesn't define the handle. NOTE: Params aren't shifted yet
			if (handlerVarName==="_log_handler" && arguments[2]) { B_REST_Utils.console_error(`Logging B_REST_API err: "${arguments[1]}"`,arguments[3]); } //WARNING: Don't switch to throwEx()
			
			if (!this[handlerVarName]) { return null; }
			
			try
			{
				const remainingArgs = [...arguments];
				remainingArgs.shift();
				
				return await this[handlerVarName].apply(this, remainingArgs);
			}
			catch (e)
			{
				B_REST_Utils.console_error(`B_REST_API: User defined handler for ${handlerVarName} threw an exception:`, e); //WARNING: Don't switch to throwEx()
			}
			
			return null;
		}
	
	
	
	//CALLS
		/*
		The following are just shortcuts, to help reducing need of importing stuff
		NOTE:
			Can also use call_getObjectUrl() to get imgs and such
		*/
		get GET()             { return B_REST_Request_GET;             }
		get GET_File()        { return B_REST_Request_GET_File;        }
		get POST()            { return B_REST_Request_POST;            }
		get POST_File()       { return B_REST_Request_POST_File;       }
		get POST_Multipart()  { return B_REST_Request_POST_Multipart;  }
		get PUT()             { return B_REST_Request_PUT;             }
		get PUT_Multipart()   { return B_REST_Request_PUT_Multipart;   }
		get PATCH()           { return B_REST_Request_PATCH;           }
		get PATCH_Multipart() { return B_REST_Request_PATCH_Multipart; }
		get DELETE()          { return B_REST_Request_DELETE;          }
		/*
		Always yield an instance of B_REST_Response. Rets via resolver or rejecter, depending on its B_REST_Response::isSuccess prop
		Expects a derived instance of B_REST_Request_base
		requestOptions:
			{
				uploadProgressCallback:   Callback as (totalBytes,transferredBytes,percent) that is only fired if the call actually starts (without premature errs)
				downloadProgressCallback: Callback as (totalBytes,transferredBytes,percent) that is only fired when the server starts sending a positive or negative response
				cache:
				{
					key: Ex "cachedResponses-bootCall" or "client-123"
				}
			}
		About caching (successful responses):
			If we pass a cache obj, then will check in local storage for an encoded JSON call
			For now, has no notion of expiration, but we could later add more props to the cache obj, ex {key,expiration},
				and prolly store the expiration flag inside the cacheObj (not in another key)
			Not caching calls that yield an err
			If we want to cache stuff that have the same path but diff method, then we should think of a scheme like "client-123-get" and "client-123-post" or something
		*/
		async call(request, requestOptions=null)
		{
			B_REST_Utils.instance_isOfClass_assert(B_REST_Request_base, request);
			
			const axiosErrHelpMsg = `\n${"*".repeat(100)}\nMost likely a syntax err in PHP; try opening the API call in a new tab\nIf a CORS prob, first thing, put in PHP:\n\theader('Access-Control-Allow-Origin: *');\n\theader('Access-Control-Allow-Headers: Authorization, Cache-Control, Accept, Content-Type, Accept-Language');\n\theader("Access-Control-Expose-Headers: *");\n\t + make sure the preflight "OPTIONS" method is supported, with "header('Access-Control-Allow-Methods: GET,POST,PUT,PATCH,DELETE,OPTIONS');".\n\tThen, if it persists, could have to play with vue.config.js's devServer.disableHostCheck or devServer.proxy props, but it shouldn't be necessary.`;
			const log_callInfo    = request.url_debug;
			const cacheKey        = requestOptions?.cache?.key ?? null;
			
			return new Promise(async(resolve, reject) => //Putting this async will cause ESLint "no-async-promise-executor" warning, so disable it in eslintrc.js (if included in proj)
			{
				//Setup a main branch to call when we've got (or made up) our B_REST_Response instance
				const finalize = async(response) =>
				{
					response.request = request;
					
					//NOTE: Check "order of events for calls" note at top of file
					
					//NOTE: Might change a successful response to err, ex if it contained debug dump or expected content type doesn't match
					response.finalize();
					
					//Check if we wanted to cache the call
					if (cacheKey && response.isSuccess)
					{
						const cacheObj  = response.cache_to();
						const cacheJSON = B_REST_Utils.json_encode(cacheObj);
						
						B_REST_Utils.localStorage_set(cacheKey, cacheJSON, /*isPersistent*/false);
					}
					
					//Update call stats + do some general logs
					{
						if (response.isSuccess)
						{
							this._lastSuccessfulCall_dt = B_REST_Utils.dt_now();
							
							this._log(`${log_callInfo} - Success`, false, response);
							this._call_updateStats(request, "success");
						}
						else
						{
							this._log(`${log_callInfo} - Got error: ${response.errorMsg}`, true, response);
							this._call_updateStats(request, "error");
						}
					}
					
					//Check to tweak response, or do something else w it
					await this._fireHandler("_tweakResponse_async_handler", response); //NOTE: Waits for handler to complete. Even if it throws, it won't break the "await" here
					
					//Resolve or reject, depending on isSuccess + if we want to reject or resolve errs (... or catch debug dump)
					if (response.debug_isDump)
					{
						B_REST_Utils.console_warn(`Caught debug dump`);
						await response.debug_isDump_output(); //NOTE: Don't resolve, to prevent random code from executing w incoherent response
						B_REST_Utils.flags_onErr_showNativeAlert = B_REST_Utils.FLAGS_ON_ERR_SHOW_NATIVE_ALERT_NEVER;
						reject(response);
					}
					else if (response.isSuccess || !this._rejectUnsuccessfulCalls) { resolve(response); } else { reject(response); }
					
					//Indicate it's done. Could intercept errs here like if errorType is a bad login or access token expired...
					this._fireHandler("_afterCall_general_handler", response); //NOTE: Could throw but it won't affect flow
					
					/*
					If we had parallel connections limit, indicate we've got one more request done, in case we wanted to start another one.
					NOTE: Resolving / rejecting doesn't call what was awaiting for it (.then()) right away, so this will be ran right after the resolving / rejecting above
					*/
					if (this._maxParallelConnections) { this._maxParallelConnections_doneRequest(); }
				};
				
				try
				{
					if (!(request instanceof B_REST_Request_base))                        { B_REST_Utils.throwEx("Expected derived instance of B_REST_Request_base"); }
					if (requestOptions!==null && !B_REST_Utils.object_is(requestOptions)) { B_REST_Utils.throwEx("requestOptions must be an obj");                    }
					
					this._log(`${log_callInfo} - Starting`, false, request);
					
					//Update stats to say something is going on
					this._call_updateStats(request, "initiated");
					
					/*
					If we want to limit the nb of Axios calls at once (to prevent having too many client connections on our server), wait until it's ok to continue
					We give details about the current request, in case we'd want to prioritize their order based on its post size or known script duration
					*/
					if (this._maxParallelConnections) { await this._maxParallelConnections_scheduleRequest(request); }
					
					//First thing, check if we need an access token and don't have
					if (request.needsAccessToken===true && !this.accessToken_isValid) //NOTE: needsAccessToken is either bool or B_REST_Request_base::NEEDS_ACCESS_TOKEN_DONT (ex for login calls)
					{
						finalize( B_REST_Response.from_err(401,"Valid access token required. If the request doesn't need one, consider setting request.needsAccessToken = false") ); return; //IMPORTANT: Don't remove the return
					}
					
					//Check to pimp the request
					await this._fireHandler("_tweakRequest_async_handler", request);
					
					//If we must check for a cached version of the call's response
					if (cacheKey && B_REST_Utils.localStorage_has(cacheKey))
					{
						const cacheJSON       = B_REST_Utils.localStorage_get(cacheKey);
						const cacheObj        = B_REST_Utils.json_decode(cacheJSON);
						const response_cached = B_REST_Response.from_cache(cacheObj);
						
						B_REST_Utils.console_info(`B_REST_API: Is using cached call ${log_callInfo}`);
						finalize(response_cached);
						return; //IMPORTANT: Don't remove the return
					}
					
					//Then we have to do it for real
					{
						/*
						NOTE:
							-Axios prefers headers in lower case + returns them so
							-If we want to add new headers, we have to change the "Access-Control-Allow-Headers" header in server to list them too
						WARNING:
							If we want to add more headers, we must specify them in server's HttpUtils::outputCORSHeaders()
						*/
						const requestHeaders = {
							accept:          request.expectsContentType,
							"cache-control": "no-cache",
						};
						if (this._lang) { requestHeaders["accept-language"]=this._lang; }
						
						//To prove we're bREST
						requestHeaders[B_REST_API.HEADERS_B_REST_IS] = 1;
						
						//NOTE: We need to eval each time, as if we leave the app open for more than a day, we could flip between daylight saving and it could ret for ex -4 instead of -5
						requestHeaders[B_REST_API.HEADERS_B_REST_TIMEZONE] = B_REST_Utils.dt_device_timeZone;
						
						//Req to help know if server should send back shared lists that might have been updated since the last call
						requestHeaders[B_REST_API.HEADERS_B_REST_LAST_SUCCESSFUL_CALL_DT] = this._lastSuccessfulCall_dt ? B_REST_Utils.dt_u(this._lastSuccessfulCall_dt) : "";
						
						if (request.data_has) { requestHeaders["content-type"] = request.data_contentType; }
						
						//Pass the access token, even when request.needsAccessToken===false, so for free data, we still know which user wanted it for logs
						if (this._accessToken_public && request.needsAccessToken!==B_REST_Request_base.NEEDS_ACCESS_TOKEN_DONT) //Either bool or B_REST_Request_base::NEEDS_ACCESS_TOKEN_DONT (ex for login calls)
						{
							requestHeaders.authorization = `Bearer ${this._accessToken_public}`;
						}
						
						/*
						Check if it has extra headers to pass, and do so in 1 single JSON encoded header, to prevent CORS hell.
						Otherwise, we would have to pimp server's HttpUtils::outputCORSHeaders() to call a bREST_Custom::x() that would define extra header names that are ok for CORS,
						and it would be hard to debug. Since B_REST_API is only used to talk w a bREST server, then there's no need to define custom headers for 3rd parties
						*/
						if (request.extraHeaders_has) { requestHeaders[B_REST_API.HEADERS_B_REST_EXTRA_HEADERS] = B_REST_Utils.json_encode(request.extraHeaders); }
						
						const axiosConfig = {
							method: request.method.toLowerCase(),
							url: `${this._baseURL}${request.url_parsed}`,
							data: request.data,
							headers: requestHeaders,
							timeout: this._timeout_msecs,
							responseType: request.fetchAsBlob ? "blob" : "text", //WARNING: "text" correctly handles text/html & application/json (auto parses too). However if we set it to "json", text/html will return NULL
							validateStatus(status)
							{
								return true; //NOTE: Always return true, so no matter what the server returns we don't reject, leaving rejections only for Axios core errs. https://www.npmjs.com/package/axios#handling-errors
							},
						};
						
						if (requestOptions)
						{
							if (requestOptions.uploadProgressCallback)
							{
								axiosConfig.onUploadProgress = function(progressEvent) //ProgressEvent {timetamp, eventPhase:0, lengthComputable:bool, loaded:int,total:int}
								{
									const percent = progressEvent.total===0 ? 0 : Math.round(progressEvent.loaded*100/progressEvent.total);
									try
									{
										requestOptions.uploadProgressCallback(progressEvent.total, progressEvent.loaded, percent);
									}
									catch (e) { B_REST_Utils.throwEx(`uploadProgressCallback hook failed, for ${log_callInfo}`); }
								};
							}
							
							if (requestOptions.downloadProgressCallback)
							{
								axiosConfig.onDownloadProgress = function(progressEvent) //ProgressEvent {timetamp, eventPhase:0, lengthComputable:bool, loaded:int,total:int}
								{
									const percent = progressEvent.total===0 ? 0 : Math.round(progressEvent.loaded*100/progressEvent.total);
									try
									{
										requestOptions.downloadProgressCallback(progressEvent.total, progressEvent.loaded, percent);
									}
									catch (e) { B_REST_Utils.throwEx(`downloadProgressCallback hook failed, for ${log_callInfo}`); }
								};
							}
						}
						
						let attempts = 1;
						let innerAxiosCall = () => axios(axiosConfig).then
							(
								axios_result =>
								{
									this._networkStatus_set(B_REST_API.NETWORK_STATUS_5_API_OK);
									
									const response = B_REST_Response.from_axios_result(axios_result);
									if (!response.isSuccess)
									{
										this._log("We'll maybe get an error in the console, in xhr.js, ex saying we got a B_REST_Response::CODES_BAD_REQUEST. There's no way to make that err disappear, because it comes from a native JS err from within Axios. Just ignore it");
									}
									
									finalize(response);
								},
								async axios_error =>
								{
									//NOTE: Make sure we have the same algo in call() & call_external(). https://www.npmjs.com/package/axios#handling-errors
									const axiosErrMsg  = axios_error?.response?.data ?? axios_error?.message ?? "Unknown"; //Has message when an instance of Error
									const axiosErrCode = axiosErrMsg==="Network Error" ? B_REST_Response.CODES_CUSTOM_NO_NETWORK : (axios_error?.response?.status ?? B_REST_Response.CODES_BAD_REQUEST);
									
									if (axiosErrCode===B_REST_Response.CODES_CUSTOM_NO_NETWORK)
									{
										//When it's the first time we get a connection prob, check how much problematic is the network connection. We don't need to recheck over and over
										if (attempts===1 && (this.networkStatus_is_3_pingedGoogle||this.networkStatus_is_5_apiOk))
										{
											await this.networkStatus_update(/*checkLevel*/"cloud",/*statusIsProlly_4_apiBadGateway*/true); //IMPORTANT: Don't put checkLevel to "api" or we'll get and infinite loop
										}
										
										if (request.offline_retry_count-attempts>=0) //NOTE: Could be Infinity
										{
											attempts++;
											await B_REST_Utils.sleep(request.offline_retry_delayMsecs);
											innerAxiosCall();
											return;
										}
									}
									
									/*
									WARNING:
										There's no way to catch msg like "Access to XMLHttpRequest at ... has been blocked by CORS policy"
										It's not an Axios prob
										https://github.com/axios/axios/issues/838
									*/
									finalize( B_REST_Response.from_err(axiosErrCode,`Got axios error: ${axiosErrMsg}.${axiosErrHelpMsg}`) );
								}
							);
						innerAxiosCall();
					}
				}
				catch (e)
				{
					finalize( B_REST_Response.from_err(B_REST_Response.CODES_BAD_REQUEST,`Fell in B_REST_API::call()'s try/catch: ${e}.${axiosErrHelpMsg}`) );
				}
			});
		}
			//To either update the "initiated", "success" or "error" stats
			_call_updateStats(request, which)
			{
				this._callStats_summary[which]++;
				
				const shortName = request.shortName || B_REST_API.CALL_STATS_FALLBACK_SHORT_NAME;
				if (!this._callStats_byShortName[shortName]) { this._callStats_byShortName[shortName] = new B_REST_CallStats(); }
				this._callStats_byShortName[shortName][which]++;
			}
		/*
		Performs a call (usually a GET) and converts the response into an object URL (something like "blob:null/as8df098as9d8f0", usually for <img src> or for downloads)
		Resolves as {objectURL, response, contentDispositionBaseNameWExt:null}
		Rejects as {errorMsg, response:null}, unless we didn't pass a request
		Usage ex:
			const request = new B_REST_Request_GET_File("/brands/123/logo?h=asdg7g9");
			request.expectsContentType_image();
			request.needsAccessToken = false;
			const { objectURL } = await call_getObjectUrl(request);
			img.src    = objectURL;
			img.onload = () => B_REST_Utils.files_objectURL_revoke(objectURL);
		Also check call_download() & call_download_inlineNewWindow()
		WARNING:
			After the objectURL is used, we must do B_REST_Utils.files_objectURL_revoke(objectURL) to prevent bloating memory
		*/
		async call_getObjectUrl(request)
		{
			B_REST_Utils.instance_isOfClass_assert(B_REST_Request_base, request);
			
			return new Promise(async(s,f) => //Putting this async will cause ESLint "no-async-promise-executor" warning, so disable it in eslintrc.js (if included in proj)
			{
				let response = null;  //Instance of B_REST_Response
				let errorMsg = null;
				
				try
				{
					response = await this.call(request);
					if (response.isSuccess)
					{
						const {objectURL,contentDispositionBaseNameWExt} = response.data_toObjectURL();
						
						s({objectURL,response,contentDispositionBaseNameWExt});
						return;
					}
					
					errorMsg = `Response not successful`;
				}
				catch (e) { errorMsg=`Got exception while doing call - Maybe should use B_REST_Request_x_File instead of GET/POST ?: ${e}`; }
				
				f({errorMsg,response});
			});
		}
		/*
		NOTE: Consider call_download_inlineNewWindow() too
		Call wrapper to download a resource at a given apiUrl
		If the call is successful, then a prompt will allow the user to save the file, and then will resolve with the B_REST_Response as {baseNameWExt, response}
		If the call fails, or for some reason the browser doesn't allow converting the file to a <a download>, then rejects as {errorMsg, response}
		Usage ex:
			1)
				const request = new B_REST_Request_GET_File("/brands/123/logo?h=asdg7g9");
				request.needsAccessToken = false;
				const { response } = call_download(request);
			2)
				const request = new B_REST_Request_GET_File("/brands/123/docs?h=89cdb0caf3b4c0f51b1330ddd0c4961e");
				request.needsAccessToken = true;
				const { response } = call_download(request, "documents.zip");
		baseNameWExt:
			Optional file name to use, if we want to override what's (maybe) returned by the call() in headers
			Depends on which of backend's methods were used:
				HttpUtils::output_file_fromSafeFilePath()
				HttpUtils::output_file_zippedDir()
				HttpUtils::output_file_fromContents()
			... but they all should yield any of:
					Content-Disposition: attachment; filename="..."
					Content-Disposition: inline; filename="..."
		WARNING: May cause infinite loop if the fake <a> click() bubbles back to the elem that started call_download()
		*/
		async call_download(request, baseNameWExt=null, domContainer=null) { return this._call_download(request,"download",baseNameWExt,domContainer); }
		/*
		Same intent as call_download(), but opens the file in a new window
		Works w HTML, images, pdf, etc
		Resolves as {baseNameWExt, response:B_REST_Response, window:Window}
		Rejects as {errorMsg, response:B_REST_Response}
		*/
		async call_download_inlineNewWindow(request,baseNameWExtOrWindowTitle=null) { return this._call_download(request,"inlineNewWindow",baseNameWExtOrWindowTitle,/*ifDownload_domContainer*/null); }
			async _call_download(request, method, baseNameWExt=null, ifDownload_domContainer=null)
			{
				B_REST_Utils.instance_isOfClass_assert(B_REST_Request_base, request);
				
				return new Promise((s,f) =>
				{
					this.call_getObjectUrl(request).then(async({objectURL,response,contentDispositionBaseNameWExt}) =>
					{
						try
						{
							//NOTE: We don't need to do code about response.debug_isDump calling response.debug_isDump_output(), because _call_download() -> call_getObjectUrl() -> call()::finalize()
							
							//If we've got no file name, try to use "Content-Disposition". Note that frontend headers are always changed to lowercase by Axios
							if (!baseNameWExt) { baseNameWExt = contentDispositionBaseNameWExt??"data"; }
							
							const tmpDOMFilePtr = new B_REST_DOMFilePtr(objectURL);
							
							switch (method)
							{
								case "download":
									await tmpDOMFilePtr.download(baseNameWExt, ifDownload_domContainer); //Might throw
									s({baseNameWExt, response});
								break;
								case "inlineNewWindow":
									const window = await tmpDOMFilePtr.download_inlineNewWindow(baseNameWExt); //Might throw
									s({baseNameWExt, response, window});
								break;
								default: B_REST_Utils.throwEx(`Unknown method "${method}"`);
							}
							
							tmpDOMFilePtr.objectURL_revoke();
						}
						catch (e)
						{
							f({errorMsg:`Got exception while downloading: ${e}`, response});
						}
					}).catch(({errorMsg,response}) =>
					{
						f({errorMsg, response});
					});
				});
			}
	
	
	
	//PARALLEL CONNECTIONS RELATED
		/*
		The following is only used when _maxParallelConnections is > 0, and especially relevant with file uploads:
			We can stack multiple files in the same POST, or send them in diff calls
			If we send them all in the same POST:
				+Uses only 1 "client connection"
				+Useful if we want everything to fail when any file doesn't match XYZ criteria
				-Takes more mem on the server
				-Might lead to error 500 for timeouts, max post size, upload time...
				-If one file is invalid, all files fail
				-If we had a 1kb file and a 1gb file, it will appear as if the 1kb file takes forever to upload
			If we send them separately:
				-Uses multiple "client connections", so other users might hang while doing actions
				+OK if we don't care whether one or another file fails
				+Takes less mem on server
				+Less chances of getting timeouts
				+Small files can finish first and not get slowed down by huge files
		It's also useful with multiple GETs for diff data sources, and if we expect calls to be ran in a given order, then we should just do await ... await ... await ... await
		We only add to _parallelConnections_queuedRequests WHEN we already reached the max nb of ongoing calls, and we sort them by weight, so ex if we have small and big files
			queued to call(), then all the small ones should go first so we end up with a smaller nb of huge files at the end
		*/
		async _maxParallelConnections_scheduleRequest(request)
		{
			B_REST_Utils.console_todo([
				`Could implement weight to sort connections, like adding "hints" in B_REST_Request for avgScriptDuration, priority, data size (don't use JSON.stringify, but Blob.size if possible)`,
			]);
			
			return new Promise(start =>
			{
				//Can we do it now ?
				if (this._parallelConnections_ongoingCount < this._maxParallelConnections)
				{
					this._parallelConnections_ongoingCount++;
					start();
				}
				//Else store it for later, placing by priority with anything that's already waiting
				else
				{
					const payloadSize    = request.data_has ? request.data_calculateSize() : 0;
					const weight         = payloadSize;
					const connectionInfo = {request, payloadSize, weight, start};
					
					this._parallelConnections_queuedRequests.push(connectionInfo);
					
					//Now sort all queued ones to decide which next should go first
					this._parallelConnections_queuedRequests.sort((connectionInfo_a, connectionInfo_b) =>
					{
						const weight_a = connectionInfo_a.weight;
						const weight_b = connectionInfo_b.weight;
						
						//Equals lower weight first
						if (weight_a===weight_b) { return 0; }
						return weight_a<weight_b ? -1 : 1;
					});
				}
			});
		}
		//When we're done with a call, check to run the next most important one in the queue (being sorted in advance)
		_maxParallelConnections_doneRequest()
		{
			//Check if we have things in the queue
			if (this._parallelConnections_queuedRequests.length>0)
			{
				const connectionInfo = this._parallelConnections_queuedRequests.shift();
				connectionInfo.start();
			}
			//Otherwise, indicate that we have one less nb of ongoing request. We don't do -- ++ above, since it leads to no change
			else { this._parallelConnections_ongoingCount--; }
		}
	
	
	
	//EXTERNAL APIS CALL
		/*
		Usage ex:
			try
			{
				const response = await B_REST_API.call_external("GET", "https://api.stripe.com/v1/elements/sessions?key=....");
					-> Contains {data, headers, status, statusText}
			}
			catch ({code, msg, responseData})
			{
				
			}
		*/
		static async call_external(method, url, data=null, headers={}, resolveErrors=false) //Do we want errs like 400 & 500 to resolve anyways or reject ?
		{
			return new Promise((s,f) =>
			{
				const axiosConfig = {
					method: method.toLowerCase(),
					url,
					data,
					headers,
				};
				
				if (resolveErrors) { axiosConfig.validateStatus = (status)=>{return true}; }
				
				axios(axiosConfig).then(
					axios_result =>
					{
						s(axios_result);
					},
					axios_error =>
					{
						//NOTE: Make sure we have the same algo in call() & call_external(). https://www.npmjs.com/package/axios#handling-errors
						const axiosErrMsg  = axios_error?.response?.data ?? axios_error?.message ?? "Unknown"; //Has message when an instance of Error
						const axiosErrCode = axiosErrMsg==="Network Error" ? B_REST_Response.CODES_CUSTOM_NO_NETWORK : (axios_error?.response?.status ?? B_REST_Response.CODES_BAD_REQUEST);
						
						//NOTE: In call(), we have retry logic for when we get a B_REST_Response.CODES_CUSTOM_NO_NETWORK, but not here for now, though we could implement
						
						f({code:axiosErrCode, msg:axiosErrMsg, responseData:axios_error.response.data});
					}
				);
			});
		}
};
